<template>
  <bl-panel-mv v-if="lastOrderScheduleTime" class="u-mrgn-top--2">
    <bl-panel-body-mv class="u-pad--3 u-fg--black">
      <bl-tag-av color="yellow" class="u-display-inline-block u-mrgn-bottom--1">bayar sebelum</bl-tag-av>
      <div class="u-txt--bold u-txt--medium u-mrgn-bottom--1 ut-merchant-last-order__schedule">
        {{ lastOrderScheduleTime }}
      </div>
      Agar barang dikirim hari ini
    </bl-panel-body-mv>
  </bl-panel-mv>
</template>

<script>
import { BlPanelMv, BlPanelBodyMv, BlTagAv } from '@bukalapak/bazaar-dweb';
import { mapState } from 'vuex';
import { humanizeTimestamp } from 'shared/javascripts/utils/date-helpers';
import DayName from './data/day-name.json';

export default {
  name: 'MerchantPageLastOrderSchedule',
  components: {
    BlPanelMv,
    BlPanelBodyMv,
    BlTagAv,
  },
  computed: {
    ...mapState('store', ['store']),
    lastOrderScheduleTime() {
      if (this.store.last_order_schedule && Object.keys(this.store.last_order_schedule).length) {
        const todayDate = new Date();
        const todayDayIdx = todayDate.getDay();
        const todayDayName = DayName[todayDayIdx];
        if (Object.keys(this.store.last_order_schedule).indexOf(todayDayName) > -1) {
          const todayDateHour = humanizeTimestamp(todayDate, '%hh%:%mi%');
          const todayDateStr = '01/01/2019';
          const lastOrderHour = this.store.last_order_schedule[todayDayName];
          const couldStillOrder =
            Date.parse(`${todayDateStr} ${todayDateHour}:00`) < Date.parse(`${todayDateStr} ${lastOrderHour}:00`);
          return couldStillOrder ? lastOrderHour : '';
        }
      }
      return '';
    },
  },
  methods: {},
};
</script>

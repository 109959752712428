import { humanizeTimestamp, humanizeTimeAgo } from 'shared/javascripts/utils/date-helpers';
import { mapState } from 'vuex';

const storeStatistics = {
  computed: {
    ...mapState('chat', ['lastSeen', 'onlineStatus']),
    ...mapState('store', ['store']),
    joinedAt() {
      return humanizeTimestamp(this.store.owner.joined_at, '%dd% %month% %yyyy%');
    },
    totalFeedback() {
      return this.store.reviews.negative + this.store.reviews.positive;
    },
    feedbackPercentage() {
      return this.totalFeedback === 0 ? 0 : Math.floor((this.store.reviews.positive / this.totalFeedback) * 100);
    },
    subscriberAmount() {
      return this.store.subscriber_amount.toLocaleString();
    },
    lastAppearAtWording() {
      return this.lastSeen ? humanizeTimeAgo(this.lastSeen) : '-';
    },
    storeFeedbackUrl() {
      return `${this.store.url}/feedback`;
    },
  },
};

export default storeStatistics;

const ACTIVE_STATE = 'active';
const initialState = () => ({
  productHighlights: [],
  selectedHighlights: null,
  isHighlightsLoading: true,
});

const getters = {};

const mutations = {
  updateProductHighlights: (state, { data, filter }) => {
    if (filter) {
      state.productHighlights = data;
    } else {
      state.productHighlights = data
        .filter(e => e.state === ACTIVE_STATE && e.products.length)
        .sort((a, b) => a.order > b.order);
    }
    if (state.productHighlights && state.productHighlights.length > 0) {
      state.selectedHighlights = state.productHighlights[0];
    }
    state.isHighlightsLoading = false;
  },
  updateSelectedHighlights: (state, payload) => {
    state.selectedHighlights = payload;
  },
  updateLoadingHighlights: (state, isLoading) => {
    state.isHighlightsLoading = isLoading;
  },
};

const actions = {
  SET_SELECTED_PH: ({ commit }, item) => {
    commit('updateSelectedHighlights', item);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

<template>
  <div class="u-mrgn-top--3">
    <bl-button-av
      v-for="link in getShareLinks"
      :key="link.name"
      class="u-mrgn-right--1"
      size="tiny"
      @click="handleShareLink(link)"
    >
      <bl-icon-av :variant="link.icon" :class="`u-fg-external--${link.color}`" size="base" />
    </bl-button-av>
  </div>
</template>

<script>
import { BlButtonAv, BlIconAv } from '@bukalapak/bazaar-dweb';
import { mapState } from 'vuex';
import queryString from 'query-string';
import { getShareLinks } from './data/links';

export default {
  name: 'MerchantPageShareButtons',
  components: {
    BlButtonAv,
    BlIconAv,
  },
  computed: {
    ...mapState('store', ['store']),
    getShareLinks() {
      return getShareLinks(this.store.name);
    },
  },
  methods: {
    handleShareLink(item) {
      const utmQuery = {
        utm_source: item.utm_source,
        utm_medium: 'bukalapakpageshare',
        utm_campaign: 'lapakshare',
      };
      const query = `${this.store.url}?${queryString.stringify(utmQuery)}`;
      const url = `${item.url}${encodeURIComponent(query)}`;
      if (typeof window !== 'undefined') {
        try {
          const win = window.open(
            url,
            '',
            'menubar=no, toolbar=no, resizable=yes, scrollbars=yes, height=600, width=600',
          );
          win.focus();
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="o-layout u-mrgn-bottom--4">
      <div class="o-layout__item">
        <bl-text-field-mv placeholder="Cari nama jasa pengiriman" v-model="couriersKeywordsData" />
      </div>
    </div>
    <div class="o-layout">
      <template v-if="isCouriersLoading">
        <filter-couriers-loader />
      </template>
      <template v-else>
        <template v-if="filteredCouriersSelection.length > 0">
          <div
            class="o-layout__item u-mrgn-bottom--3"
            v-for="(courier, index) in filteredCouriersSelection"
            :key="index"
          >
            <bl-checkbox-av :label="courier.name" v-model="productCouriersData" color="red" :value="courier.name" />
          </div>
        </template>
        <template v-else>
          <div class="o-layout__item u-12of12 u-align-center">
            Nama kurir tidak ditemukan
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { BlTextFieldMv, BlCheckboxAv } from '@bukalapak/bazaar-dweb';
import filterCouriers from 'shared/javascripts/mixins/filter/couriers';
import FilterCouriersLoader from './FilterCouriersLoader';

export default {
  name: 'ProductCouriers',
  components: {
    BlTextFieldMv,
    BlCheckboxAv,
    FilterCouriersLoader,
  },
  mixins: [filterCouriers],
  props: {
    couriersKeywords: {
      type: String,
      default: '',
    },
  },
  computed: {
    couriersKeywordsData: {
      get() {
        return this.couriersKeywords;
      },
      set(newValue) {
        this.$emit('couriers-keywords-change', newValue);
      },
    },
    filteredCouriersSelection() {
      const filterString = this.couriersKeywordsData.toLowerCase();

      if (filterString === '') {
        return this.couriers;
      }
      if (this.couriers) {
        const cloneCourierSelection = [...this.couriers];
        return cloneCourierSelection.filter(courier => courier.name.toLowerCase().indexOf(filterString) >= 0);
      }
      return '';
    },
  },
};
</script>

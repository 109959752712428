<template>
  <bl-panel-body-mv class="u-pad-bottom--3 u-position-relative">
    <div class="u-position-absolute" style="right: 18px">
      <template v-if="!isCurrentUserStore">
        <a
          :data-user-id="store.id"
          :data-track-open-chat-room="chatTrackerData"
          :href="chatStoreUrl"
          class="js-start-chat"
        >
          <bl-button-av class="merchant-page__button u-mrgn-right--1">
            <bl-dot-av :class="{ 'u-bg--green': onlineStatus }" class="merchant-page__dot u-mrgn-right--1" /> Kirim Chat
          </bl-button-av>
        </a>
      </template>
      <template v-else>
        <a class="ut-atur-button" target="_blank" :href="sellerCenterSettingsUrl">
          <bl-button-av size="small">
            <bl-icon-av variant="settings" />
            Atur Lapak
          </bl-button-av>
        </a>
      </template>
    </div>
    <bl-flag-mv type="medium" valign="top">
      <bl-flag-head-av>
        <a
          rel="nofollow"
          data-testid="ut-avatar"
          class="c-avatar merchant-page__avatar u-mrgn-bottom--1"
          :href="store.url"
        >
          <img :alt="storeName" :src="avatarUrl" class="c-avatar__image" />
        </a>
      </bl-flag-head-av>
      <bl-flag-body-av>
        <h1 class="u-txt--fair u-txt--bold merchant-page__store-name ut-store-name" :title="storeName">
          {{ storeName }}
        </h1>
        <div class="u-display-inline-block" v-if="store.brand_seller">
          <img class="u-position-relative ut-bukamall-logo" :src="BUKAMALL_LOGO_URL" style="height: 26px; top: -3px;" />
        </div>
        <span
          v-else-if="store.premium_top_seller && !isSuperSellerActive"
          class="c-label c-label--lapak-terbaik u-txt--capitalize u-valign-top ut-top-seller-badge"
        >
          Lapak Terbaik
        </span>
        <div v-else-if="isSuperSellerWithRebrandingBadge" class="ic-new_superseller u-mrgn-left--2 u-valign-top" />
        <div v-else-if="isSuperSeller" class="c-label c-label--super-seller u-mrgn-left--2 u-valign-top">
          Super Seller
        </div>
        <div class="u-display-block u-mrgn-bottom--2 u-fg--ash-dark u-txt--small">
          <a class="ut-store-city u-fg--ash-dark" :href="searchUrlBasedOnLocation" rel="nofollow">
            {{ store.address.city }}
          </a>
          <template v-if="store.brand_seller">
            • Jaminan 100% Orisinal
          </template>
        </div>
        <template v-if="store.brand_seller">
          <bl-text-av style="padding-bottom: 44px;" size="12">{{ brand.description }}</bl-text-av>
        </template>
        <template v-else>
          <bl-seller-badge-av
            v-if="store.level.image_url"
            :badgeUrl="store.level.image_url"
            size="small"
            class="u-mrgn-right--1 u-txt--small ut-seller-badge"
            hollow
          />
          <span v-if="store.level.name" class="u-mrgn-right--3 u-valign-middle">{{ store.level.name }}</span>
          <template v-if="isPremiumStore && !isSuperSellerActive">
            <bl-seller-badge-av level="premium" size="small" class="u-mrgn-right--1 ut-premium-badge" />
            <bl-hyperlink-av
              class="u-valign-middle ut-premium-link"
              variant="quaternary"
              :href="premiumUrlFromBadgeSeller"
              rel="noopener"
              target="_blank"
            >
              Pelapak Premium
            </bl-hyperlink-av>
          </template>
        </template>
      </bl-flag-body-av>
    </bl-flag-mv>
    <MerchantPageInfoTab v-if="isShownMerchantPageInfoTab" />
  </bl-panel-body-mv>
</template>

<script>
import { mapState } from 'vuex';
import {
  BlSellerBadgeAv,
  BlButtonAv,
  BlHyperlinkAv,
  BlIconAv,
  BlDotAv,
  BlFlagMv,
  BlFlagHeadAv,
  BlFlagBodyAv,
  BlPanelBodyMv,
} from '@bukalapak/bazaar-dweb';
import { BlTextAv } from '@bukalapak/bazaar-mweb';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import BukamallMixin from 'shared/javascripts/mixins/bukamall';
import MerchantPageInfoTab from './MerchantPageInfoTab';

export default {
  name: 'MerchantInfo',
  components: {
    BlSellerBadgeAv,
    BlButtonAv,
    BlHyperlinkAv,
    BlIconAv,
    BlDotAv,
    BlFlagMv,
    BlFlagHeadAv,
    BlFlagBodyAv,
    BlPanelBodyMv,
    BlTextAv,
    MerchantPageInfoTab,
  },
  mixins: [StoreStateMixin, BukamallMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('store', ['store']),
    ...mapState('chat', ['onlineStatus']),
    ...mapState('toggleFeatures', ['toggle']),
    avatarUrl() {
      return this.brand.logo_url || this.store.avatar_url;
    },
    storeName() {
      return this.brand.name || this.store.name;
    },
    searchUrlBasedOnLocation() {
      const { store } = this;
      const { address } = store;
      return store && address && Object.keys(address).length
        ? `/products?search%5Bcity%5D=${encodeURIComponent(address.city)}&search%5Bprovince%5D=${encodeURIComponent(
            address.province,
          )}`
        : '';
    },
    chatStoreUrl() {
      return `/messages/${this.store.id}`;
    },
    chatTrackerData() {
      return JSON.stringify({
        buttonReferrer: 'page',
        partnerId: this.store.id.toString(36),
      });
    },
    sellerCenterSettingsUrl() {
      return `${process.env.SELLER_CENTER_URL}/settings`;
    },
    isPremiumStore() {
      return this.store.premium_level !== 'none';
    },
    isSuperSellerActive() {
      return this.toggle['premium/super_seller'];
    },
    isShownMerchantPageInfoTab() {
      const { reviews, description, term_and_condition: termAndCondition } = this.store;
      const totalFeedbacks = reviews.negative + reviews.positive;
      return !!totalFeedbacks || !!description || !!termAndCondition;
    },
    premiumUrlFromBadgeSeller() {
      const application = window.APP;
      return `${application.wwwHost}/premium/packages/available?from=premium_badge_seller`;
    },
    isSuperSellerRebrandingEnabled() {
      return this.toggle['super-seller-rebranding-enabled'];
    },
    isSuperSellerWithRebrandingBadge() {
      return this.isSuperSellerRebrandingEnabled && this.store.premium_top_seller && this.isSuperSellerActive;
    },
    isSuperSeller() {
      return (
        this.store.premium_top_seller && this.isSuperSellerActive && this.isSuperSellerRebrandingEnabled !== undefined
      );
    },
  },
};
</script>
<style src="./MerchantPageInfo.style.scss" lang="scss" scoped></style>

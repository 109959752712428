<template>
  <bl-card-head-mv class="revamp-c-card--head">
    <a :title="product.name" :href="productUrl" rel="nofollow" @mousedown="$emit('hit-product')">
      <div class="ratio-container">
        <WebP :src="imageProduct" webp>
          <bl-image class="ratio-img c-card__img lazyload" :data-src="imageProduct" :alt="product.name" />
        </WebP>
      </div>
    </a>
    <bl-card-head-badge-mv v-if="hasDeal">
      <bl-badge-av :pill="cardType === 'feed'" color="pink" :size="cardType === 'feed' ? 'default' : 'large'"
        >{{ product.deal.percentage }}%</bl-badge-av
      >
    </bl-card-head-badge-mv>
    <bl-card-head-label-mv v-if="product.condition === 'Bekas'">
      <bl-tag-av color="default">Bekas</bl-tag-av>
    </bl-card-head-label-mv>
    <bl-card-head-label-mv v-if="product.wholesales.length > 0">
      <bl-tag-av color="yellow">Grosir</bl-tag-av>
    </bl-card-head-label-mv>
  </bl-card-head-mv>
</template>

<script>
import { BlCardHeadMv, BlCardHeadBadgeMv, BlCardHeadLabelMv, BlBadgeAv, BlTagAv } from '@bukalapak/bazaar-dweb';
import { DEFAULT_IMAGE_PRODUCT } from 'shared/javascripts/utils/images';
import BlImage from '../../BlImage';
import WebP from '../../WebP';

export default {
  name: 'ProductCardHead',
  components: {
    BlCardHeadMv,
    BlCardHeadBadgeMv,
    BlCardHeadLabelMv,
    BlBadgeAv,
    BlTagAv,
    BlImage,
    WebP,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
    query: {
      type: String,
      default: '',
    },
    cardType: {
      type: String,
      default: '',
      validator(value) {
        return ['productHighlight', 'feed', ''].indexOf(value) > -1;
      },
    },
  },
  computed: {
    hasDeal() {
      const { deal } = this.product;
      if (deal && deal.applied_date && deal.expired_date) {
        const validAppliedDate = Date.parse(deal.applied_date) <= Date.now();
        const validExpiredDate = Date.parse(deal.expired_date) > Date.now();
        return validAppliedDate && validExpiredDate;
      }
      return false;
    },
    imageProduct() {
      const { product } = this;
      return product.images.large_urls && product.images.large_urls.length
        ? product.images.large_urls[0]
        : DEFAULT_IMAGE_PRODUCT;
    },
    productUrl() {
      return this.query ? `${this.product.url}?${this.query}` : this.product.url;
    },
  },
};
</script>

<style src="./RevampProductCardHead.style.scss" lang="scss" scoped></style>

<template>
  <Modal
    :name="modalName"
    class="age-restriction-modal"
    size="medium"
    :overlayClickToClose="false"
    :showCloseBtn="false"
  >
    <bl-panel-mv>
      <bl-panel-body-mv>
        <bl-flag-mv reverse>
          <bl-flag-head-av style="width: 100px;">
            <img
              class="u-display-block u-mrgn-autu"
              data-testid="ut-illust"
              :src="getAssetUrl('images/age_restriction.png')"
            />
          </bl-flag-head-av>
          <bl-flag-body-av>
            <div class="u-mrgn-top--1 u-txt--bold" data-testid="ut-desc-1" style="font-size: 26px;">
              Apakah kamu berusia 18+?
            </div>
            <div class="u-mrgn-v--1 u-txt--fair" data-testid="ut-desc-2">
              Halaman ini hanya bisa diakses jika kamu berusia 18 tahun atau lebih
            </div>
          </bl-flag-body-av>
        </bl-flag-mv>
      </bl-panel-body-mv>
      <bl-panel-footer-mv class="u-align-right">
        <bl-button-av
          class="btn-age-restriction btn-age-restriction__back u-mrgn-right--2"
          data-testid="ut-btn-back"
          :activated="isLoadingBack"
          has-spinner
          @click="back"
          >Tidak</bl-button-av
        >
        <bl-button-av
          class="btn-age-restriction"
          data-testid="ut-btn-confirm"
          color="red"
          @click="$BLRemodal.hide(modalName)"
          >Ya, saya berusia 18+</bl-button-av
        >
      </bl-panel-footer-mv>
    </bl-panel-mv>
  </Modal>
</template>

<script>
import {
  BlButtonAv,
  BlFlagMv,
  BlFlagHeadAv,
  BlFlagBodyAv,
  BlPanelMv,
  BlPanelBodyMv,
  BlPanelFooterMv,
} from '@bukalapak/bazaar-dweb';
import AssetsMixin from 'shared/javascripts/mixins/assets';

export default {
  name: 'MerchantPageAgeRestrictionModal',
  mixins: [AssetsMixin],
  components: {
    BlButtonAv,
    BlFlagMv,
    BlFlagHeadAv,
    BlFlagBodyAv,
    BlPanelMv,
    BlPanelBodyMv,
    BlPanelFooterMv,
  },
  data: () => ({
    modalName: 'age-restriction-modal',
    isLoadingBack: false,
  }),
  methods: {
    back() {
      this.isLoadingBack = true;
      const { BUKALAPAK_URL } = process.env;
      const back = document.referrer || BUKALAPAK_URL;
      window.location.assign(back);
    },
  },
};
</script>
<style lang="scss">
.age-restriction-modal {
  .c-remodal__content {
    width: 560px;
  }
  .c-remodal__panel__body {
    padding: 0 !important;
  }
}
.btn-age-restriction {
  height: 44px;
  border-radius: 4px;
  &__back {
    background: none;
    border: 1px solid white;
    -webkit-box-shadow: none;
    box-shadow: none;
    &:hover {
      background: #f5f5f5 !important;
    }
  }
}
</style>

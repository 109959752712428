import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['me']),
    ...mapState('store', ['store', 'brand']),
    isUserLogin() {
      return !!global.USER.id || (!!this.me && !!this.me.id);
    },
    isCurrentUserStore() {
      return (
        this.store.id === parseInt(global.USER.id, 10) || this.store.id === parseInt(this.me ? this.me.id : null, 10)
      );
    },
    isAdmin() {
      return !!this.store.isAdmin;
    },
    userIdentity() {
      if (global.USER) {
        return global.USER.identity;
      }

      return false;
    },
  },
};

<template>
  <bl-panel-mv class="revamp-product-etalase">
    <bl-panel-head-mv>
      <bl-panel-title-av class="revamp-product-etalase-title">Etalase</bl-panel-title-av>
    </bl-panel-head-mv>
    <bl-panel-body-mv>
      <template v-if="isStoreLoading">
        <bl-preloader-av
          :key="`label-pl-${index}`"
          v-for="index in 6"
          class="u-txt--normal"
          :wrapper-style="{ width: '100%' }"
          loading-class="u-border-radius--2 u-mrgn-h--3 u-mrgn-v--2"
        />
      </template>
      <template v-else>
        <bl-list-ui-mv link scrollable class="revamp-product-etalase-body">
          <bl-list-item-av>
            <a
              class="c-list-ui__link"
              :class="labelSlug === '' ? 'label-active' : ''"
              :href="routePath"
              rel="nofollow"
              data-testid="ut-label-link"
              @click.prevent="changeLabel()"
            >
              Semua Barang
            </a>
          </bl-list-item-av>
          <bl-list-item-av v-for="label in labels" :key="`label-${label.id}`">
            <a
              class="c-list-ui__link"
              :class="labelSlug === label.slug ? 'label-active' : ''"
              :href="`${routePath}/label/${label.slug}`"
              rel="nofollow"
              data-testid="ut-label-link"
              @click.prevent="changeLabel(label)"
            >
              {{ label.name }}
            </a>
          </bl-list-item-av>
        </bl-list-ui-mv>
      </template>
    </bl-panel-body-mv>
  </bl-panel-mv>
</template>

<script>
import {
  BlPanelMv,
  BlPanelHeadMv,
  BlPanelTitleAv,
  BlPanelBodyMv,
  BlListUiMv,
  BlListItemAv,
  BlPreloaderAv,
} from '@bukalapak/bazaar-dweb';
import MerchantPageLabelsMixin from 'shared/javascripts/mixins/merchant-page/labels';

export default {
  name: 'MerchantPageEtalase',
  mixins: [MerchantPageLabelsMixin],
  components: {
    BlPanelMv,
    BlPanelHeadMv,
    BlPanelTitleAv,
    BlPanelBodyMv,
    BlListUiMv,
    BlListItemAv,
    BlPreloaderAv,
  },
};
</script>

<style src="./MerchantPageEtalase.style.scss" lang="scss" scoped></style>

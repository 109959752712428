<template>
  <a :href="url" class="c-label-item">
    <WebP class="label-image" :src="image" :webp="true">
      <BlImage class="ut-image-labels label-image" :data-src="image" :alt="name" />
    </WebP>
  </a>
</template>

<script>
import WebP from 'shared/javascripts/components/WebP';
import BlImage from 'shared/javascripts/components/BlImage';

export default {
  name: 'BrandPageLabelsItem',
  components: {
    BlImage,
    WebP,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.c-label-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 90px;

  @media only screen and (min-width: 82.563em) {
    height: 105px;
  }
}
.label-image {
  @media only screen and (min-width: 82.563em) {
    width: 100% !important;
  }
}
</style>

const NEO_CONFIG_PATH = `/_exclusive/neo/configs`;

const neoInterface = {
  getNeoConfigs(data) {
    return this.post(NEO_CONFIG_PATH, 'public', { data });
  },
};

module.exports = {
  NEO_CONFIG_PATH,
  neoInterface,
};

import 'plugins/vue-flash-message';
import { parse } from 'zipson';
import Vue from 'vue';
import Vuex from 'vuex';
import { createApp } from './app';
import { initialApp, initialAppId, mountPointId } from './app-constant';
import { SET_BASE_STATE } from './config/stores/mutations';

const { app, router, store } = createApp({ Vue, Vuex });

// eslint-disable-next-line
const merchantFragment = document.getElementById('merchant-fragment-data');

let updatedPath;
let sellerData;
let productHightlight;
let products;
let toggleStatus;
let currentUser;

if (window[initialApp]) {
  const dataString = window[initialApp];

  try {
    ({ updatedPath, sellerData, productHightlight, products, toggleStatus, currentUser } = parse(unescape(dataString)));
  } catch (e) {
    /** */
  }
}
const initSellerData = () => {
  const stores = store.state.store;
  const { seller, brand, labels, labelId } = sellerData;

  const productFilters = {
    ...store.state.filters,
    ...(labelId ? { label_id: labelId } : ''),
    ...(products && products.filters ? products.filters : ''),
  };

  if (seller && seller.isAdmin) {
    store.commit('store/updateStore', { store: { isAdmin: seller.isAdmin }, labels: labels || [] });
  }

  if (seller && seller.id) {
    store.commit('store/updateStore', { store: { ...stores, ...seller }, labels: labels || [] });
    store.commit('store/updateFilters', productFilters);
  }

  if (brand && brand.id) {
    store.commit('store/updateBrand', brand);
  }

  store.commit('store/updateLoadingStore', false);

  if (productHightlight) {
    const filteredHighlights = productHightlight
      .filter(e => e.state === 'active' && e.products.length)
      .sort((a, b) => a.order > b.order);
    store.commit('productHighlights/updateProductHighlights', { data: filteredHighlights, filter: true });
    store.commit('productHighlights/updateLoadingHighlights', false);
  }

  if (toggleStatus) {
    store.commit('toggleFeatures/updateToggle', toggleStatus);
  }

  if (products) {
    store.commit('store/updateProducts', { data: products.data, meta: products.meta });
    store.commit('store/updateLoadingProduct', false);
  }

  if (currentUser) {
    store.commit(SET_BASE_STATE, { name: 'me', payload: currentUser || [] });
  }

  store.commit(SET_BASE_STATE, { name: 'updatedPath', payload: updatedPath || '' });
};

const removeInitialData = () => {
  let initMerchantScript = document.getElementById(initialAppId);
  if (initMerchantScript) {
    initMerchantScript.parentElement.removeChild(initMerchantScript);
    initMerchantScript = null;
    delete global[initialApp];
  }
  if (document.getElementById('merchant-page-loader')) {
    document.getElementById('merchant-page-loader').remove();
  }
};

if (sellerData) {
  initSellerData();
  removeInitialData();
} else if (merchantFragment) {
  sellerData = JSON.parse(unescape(merchantFragment.dataset.sellerData));
  const { seller, brand, labels } = sellerData;
  const stores = store.state.store;

  if (sellerData && sellerData.isAdmin) {
    store.commit('store/updateStore', { store: { isAdmin: seller.isAdmin }, labels: labels || [] });
  }

  if (seller && seller.id) {
    store.commit('store/updateStore', { store: { ...stores, ...seller }, labels: labels || [] });
  }

  if (brand && brand.id) {
    store.commit('store/updateBrand', brand);
  }

  store.commit(SET_BASE_STATE, { name: 'updatedPath', payload: sellerData.updatedPath || '' });
  removeInitialData();
}

router.onReady(() => {
  app.$mount(`#${mountPointId}`);
});

<template>
  <div>
    <div class="o-layout__item" v-for="n in 6" :key="'preload' + n">
      <bl-preloader-av
        class="u-txt--base"
        :wrapper-style="{
          width: '100%',
        }"
        loading-class="u-border-radius--10 u-mrgn-bottom--3"
      />
    </div>
  </div>
</template>
<script>
import { BlPreloaderAv } from '@bukalapak/bazaar-dweb';

export default {
  name: 'ProductCouriersPreLoader',
  components: {
    BlPreloaderAv,
  },
};
</script>

<template>
  <BlFlexContainerMv direction="row" v-if="!loading">
    <BlFlexItemAv
      basis="20%"
      shrink="1"
      v-for="(item, index) in brandLabels"
      :key="`brand-label-${index}`"
      :margin="`0px 0px 0px ${index ? '12px' : '0px'}`"
      class="c-banner-thumb__item c-banner-thumb__label"
    >
      <BrandPageLabelsItem
        :url="`${routePath}/label/${item.slug}`"
        :name="item.name"
        :image="item.image.desktop_url"
        @click.native.prevent="changeLabel(item)"
        class="ut-labels c-banner-thumb__img"
      />
    </BlFlexItemAv>
  </BlFlexContainerMv>
  <BlFlexContainerMv class="ut-labels-loading-container" direction="row" v-else>
    <BlFlexItemAv
      basis="20%"
      shrink="1"
      v-for="index in 6"
      :key="`brand-label-loading-${index}`"
      :margin="`0px 0px 0px ${index > 1 ? '12px' : '0px'}`"
    >
      <BlPreloaderAv loading-class="u-height--90 u-width-1 ut-labels-loading" />
    </BlFlexItemAv>
  </BlFlexContainerMv>
</template>

<script>
import { BlPreloaderAv } from '@bukalapak/bazaar-dweb';
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import MerchantPageLabelsMixins from 'shared/javascripts/mixins/merchant-page/labels';
import BrandPageLabelsItem from './BrandPageLabelsItem';

export default {
  name: 'BrandPageLabels',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BrandPageLabelsItem,
    BlPreloaderAv,
  },
  mixins: [MerchantPageLabelsMixins],
  props: {
    brandLabels: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.c-banner-thumb__label {
  overflow: hidden;
  border-radius: 4px;
  background-color: #333;
}
</style>

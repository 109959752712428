import { me } from 'shared/javascripts/api/merchant/api-modules';
import { SET_BASE_STATE } from './mutations';

export default (() => {
  function fetchMe({ commit, state }) {
    me.retrieveCurrentUser().then(res => {
      commit(SET_BASE_STATE, {
        name: 'me',
        payload: {
          ...state.me,
          ...res.data,
        },
      });
    });
  }
  return {
    fetchMe,
  };
})();

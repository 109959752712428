import { onboardings } from 'shared/javascripts/api/merchant/api-modules';

const initialState = () => ({
  isOnBoardingLoading: true,
});

const getters = {};

const mutations = {
  updateLoadingOnBoarding: (state, isLoading) => {
    state.isOnBoardingLoading = isLoading;
  },
};

const actions = {
  GET_ONBOARDING_STATUS: async ({ commit }, query) => {
    const data = await onboardings.getOnboardingStatus({ queryParams: query });
    commit('updateLoadingOnBoarding', false);
    return data;
  },
  SET_ONBOARDING_STATUS: async (_, query) => {

    const data = await onboardings.setStoreOnboardingStatus({ data: query });
    return data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

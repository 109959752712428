import MerchantPage from 'fragments/merchant_page_westeros_dweb/pages/MerchantPage.vue';

const routes = [
  {
    name: 'merchant-page-label',
    path: '/u/:username/label/:label_slug',
    component: MerchantPage,
  },
  {
    name: 'merchant-page-index',
    path: '/u/:username',
    component: MerchantPage,
  },
  {
    name: 'merchant-page-index-users',
    path: '/users/:id',
    component: MerchantPage,
  },
  {
    name: 'merchant-page-label-users',
    path: '/users/:id/label/:label_slug',
    component: MerchantPage,
  },
  {
    name: 'brand-page-index',
    path: '/:brand',
    component: MerchantPage,
  },
  {
    name: 'brand-page-label',
    path: '/:brand/label/:label_slug',
    component: MerchantPage,
  },
];
export default routes;

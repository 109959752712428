<template>
  <bl-panel-mv class="u-fg--black">
    <bl-panel-head-mv class="u-pad-h--3 u-pad-bottom--2 u-border--0">
      <div class="u-txt--bold u-txt--base">Voucher Lapak</div>
    </bl-panel-head-mv>
    <bl-panel-body-mv class="u-pad-h--3 u-pad-v--0 list-voucher">
      <template v-if="vouchers.length">
        <revamp-voucher
          class="u-mrgn-bottom--2"
          :label="vouchers[0].label"
          :min-purchase="vouchers[0].min_purchase"
          :amount="vouchers[0].amount"
          :store-name="store.name"
          :code="vouchers[0].voucher_code"
          :voucher-type="vouchers[0].premium_voucher_type"
          :allowed-courier="allowedCourier(vouchers[0])"
          :percentage-setting="percentageSetting(vouchers[0])"
          @code-copied="sendTracker"
        />
      </template>
      <bl-button-av
        class="u-mrgn-bottom--2 u-txt--small u-bg--white u-border--1--red-brand u-fg--red-brand"
        v-if="vouchers.length > 1"
        block
        @click="$BLRemodal.show('modal-vouchers')"
      >
        Lihat {{ vouchers.length - 1 }} Voucher Lainnya
      </bl-button-av>
    </bl-panel-body-mv>
    <Modal
      v-if="vouchers.length >= 1"
      name="modal-vouchers"
      title="Voucher Lapak Ini"
      size="large"
      has-footer
      class="modal-vouchers"
    >
      <div
        class="o-layout o-layout--responsive"
        :style="{
          maxWidth: `${4 * 296}px`,
          width: `${vouchers.length * 296}px`,
        }"
      >
        <div v-for="voucher in vouchers" :key="`vchr-${voucher.voucher_code}`" class="o-layout__item item-voucher">
          <revamp-voucher
            :label="voucher.label"
            :min-purchase="voucher.min_purchase"
            :amount="voucher.amount"
            :store-name="store.name"
            :code="voucher.voucher_code"
            :voucher-type="voucher.premium_voucher_type"
            :allowed-courier="allowedCourier(voucher)"
            :percentage-setting="percentageSetting(voucher)"
            @code-copied="sendTracker(voucher)"
          />
        </div>
      </div>
    </Modal>
  </bl-panel-mv>
</template>

<script>
import { mapState } from 'vuex';
import { BlButtonAv, BlPanelMv, BlPanelBodyMv, BlPanelHeadMv } from '@bukalapak/bazaar-dweb';
import { trackPremiumVoucher } from 'shared/javascripts/utils/tracker';
import RevampVoucher from 'shared/javascripts/components/RevampVoucher';

export default {
  name: 'MerchantPagePremiumVoucher',
  components: {
    BlButtonAv,
    BlPanelMv,
    BlPanelBodyMv,
    BlPanelHeadMv,
    RevampVoucher,
  },
  computed: {
    ...mapState('store', ['store', 'vouchers']),
    hasMatchingVoucher() {
      return Boolean(
        this.$route.query.voucher_code &&
          this.vouchers.find(voucher => voucher.voucher_code === this.$route.query.voucher_code),
      );
    },
  },
  mounted() {
    if (this.hasMatchingVoucher) {
      this.$BLRemodal.show('modal-vouchers');
    }
  },
  methods: {
    sendTracker(voucher) {
      trackPremiumVoucher({
        code: voucher.voucher_code,
        voucherType: voucher.premium_voucher_type,
        userId: (global.USER && global.USER.id36) || '',
      });
    },
    allowedCourier(voucher) {
      return (voucher.shipping_setting && voucher.shipping_setting.allowed_courier.join()) || '';
    },
    percentageSetting(voucher) {
      return voucher.percentage_setting || {};
    },
  },
};
</script>

<style src="./MerchantPagePremiumVoucher.style.scss" lang="scss" scoped></style>

const bannerMixins = {
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  computed: {
    mainBanner() {
      return this.banners.filter(item => item.index === 1);
    },
    secondaryBanner() {
      return this.banners.filter(item => item.index !== 1);
    },
  },
};

export default bannerMixins;

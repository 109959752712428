const { Tracker } = global;

export const trackProductHighlight = ({ merchantId, sectionName, productId, position }) => {
  const trackData = {
    evn: 'product_highlight_hit',
    merchant_id: parseInt(merchantId, 36),
    list_name: sectionName,
    product_id: parseInt(productId, 36).toString(),
    position: (position + 1).toString(),
    ui: (window.USER && window.USER.id36) || '',
  };
  Tracker.event(trackData);
};

export const trackPremiumVoucher = ({ code, voucherType }) => {
  const trackData = {
    evn: 'copy_premium_seller_voucher',
    ui: (window.USER && window.USER.id36) || '',
    referrer: 'pelapak_page',
    voucher_code: code,
    voucher_type: voucherType,
  };
  Tracker.event(trackData);
};

export const trackAddToCart = ({ cart, cartItem, product, merchantId, totalFeedback, referrerEvent }) => {
  const trackData = {
    evn: 'add_to_cart',
    ui: (window.USER && window.USER.id36) || '',
    referrer_event: referrerEvent,
    cart_id: cart.id.toString(),
    cart_item_id: cartItem.id.toString(),
    product_id: parseInt(product.id, 36).toString(),
    category_id: product.category.id.toString(),
    seller_id: merchantId.toString(),
    product_rating: product.rating.average_rate || undefined,
    seller_feedbacks: totalFeedback || undefined,
    reviews: product.rating.user_count || undefined,
    is_discount: !!Object.keys(product.deal).length,
    is_wholesales: !!product.wholesales.length,
    url: document.URL,
    referrer_url: '',
  };
  Tracker.event(trackData);
};

export const trackPageviewGA = () => {
  const { ga } = global;
  if (ga) {
    ga('set', 'location', window.location.href);
    ga('set', 'page', '/user_profile');
    ga('send', 'pageview');
    ga('totalTracker.send', 'pageview');
  }
};

export const trackPageview = referrer => {
  const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  const trackData = {
    evn: 'pageviews',
    platform: 'desktop',
    td_host: window.location.hostname,
    td_path: window.location.pathname,
    td_referrer: referrer || document.referrer,
    td_title: document.title,
    td_url: window.location.href,
    td_viewport: `${vw}x${vh}`,
    ui: window.USER.id36,
  };
  Tracker.event(trackData);
};

export const trackFeedItems = ({ merchantId, sellerPosition, itemType, itemId, position, platform }) => {
  const trackData = {
    evn: 'feed_items',
    merchant_id: merchantId,
    seller_position: sellerPosition,
    item_position: position,
    item_type: itemType,
    item_id: itemId,
    platform,
    ui: (window.USER && window.USER.id36) || '',
  };

  Tracker.event(trackData);
};

export const trackFeedScroll = ({ pageOpen, callTimestamp, successTimestamp, itemsLoaded }) => {
  const trackData = {
    evn: 'feed_scroll',
    page_open: pageOpen,
    call_timestamp: callTimestamp,
    success_timestamp: successTimestamp,
    items_loaded: itemsLoaded,
    ui: (window.USER && window.USER.id36) || '',
  };

  Tracker.event(trackData);
};

export default {
  trackProductHighlight,
  trackPremiumVoucher,
  trackAddToCart,
  trackPageviewGA,
  trackPageview,
  trackFeedItems,
  trackFeedScroll,
};

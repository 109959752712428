const assetUrl = require('@bukalapak/lithosphere/lib/config-loader/webpack/helpers/asset-url');

const AssetsMixin = {
  methods: {
    getAssetUrl(path) {
      return assetUrl(path);
    },
  },
};

export default AssetsMixin;

<template>
  <bl-tab-mv class="u-txt--small" ref="stats" inside :active-tab-name.sync="activeTabName">
    <bl-tab-nav-mv class="merchant-page__nav">
      <bl-tab-nav-item-av v-if="haveFeedbacks" list inside name="stats">
        Statistik Pelapak
      </bl-tab-nav-item-av>
      <bl-tab-nav-item-av v-if="store.description" list inside name="desc">
        Deskripsi
      </bl-tab-nav-item-av>
      <bl-tab-nav-item-av v-if="store.term_and_condition" list inside name="note">
        Catatan Pelapak
      </bl-tab-nav-item-av>
    </bl-tab-nav-mv>
    <bl-tab-content-mv v-if="haveFeedbacks" class="u-pad-top--3 is-active" name="stats">
      <bl-tab-content-body-mv class="u-pad-h--4 u-bg--sand-light">
        <merchant-statistic />
      </bl-tab-content-body-mv>
    </bl-tab-content-mv>
    <bl-tab-content-mv v-if="store.description" class="u-pad-top--3" name="desc">
      <bl-tab-content-body-mv class="u-pad-h--4 u-bg--sand-light">
        {{ store.description }}
      </bl-tab-content-body-mv>
    </bl-tab-content-mv>
    <bl-tab-content-mv v-if="store.term_and_condition" class="u-pad-top--3" name="note">
      <client-only>
        <bl-tab-content-body-mv
          v-html="store.term_and_condition"
          class="u-pad-h--4 u-bg--sand-light merchant-page__note"
        />
      </client-only>
    </bl-tab-content-mv>
  </bl-tab-mv>
</template>

<script>
import { BlTabMv, BlTabNavMv, BlTabContentMv, BlTabContentBodyMv, BlTabNavItemAv } from '@bukalapak/bazaar-dweb';
import { mapState } from 'vuex';

import MerchantStatistic from '../MerchantPageStatistic';

export default {
  name: 'MerchantPageInfoTab',
  components: {
    BlTabMv,
    BlTabNavMv,
    BlTabNavItemAv,
    BlTabContentMv,
    BlTabContentBodyMv,
    MerchantStatistic,
  },
  data() {
    return {
      activeTabName: 'stats',
    };
  },
  computed: {
    ...mapState('store', ['store']),
    haveFeedbacks() {
      return this.store.reviews.negative + this.store.reviews.positive;
    },
  },
  created() {
    if (!this.haveFeedbacks) {
      this.activeTabName = this.store.description ? 'desc' : 'note';
    }
  },
};
</script>
<style lang="scss" scoped>
.merchant-page {
  &__note {
    & /deep/ ul {
      list-style: disc;
      margin-top: 1em;
      margin-bottom: 1em;
      padding-left: 40px;
    }
  }
  &__nav {
    margin-top: -44px;
    margin-left: 142px;

    /deep/ .is-active > .c-tab__link {
      color: #d71149 !important;
    }
  }
}
</style>

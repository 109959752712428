<template>
  <BlFlexContainerMv direction="row">
    <BlFlexItemAv basis="58.33333%" padding="0px 12px 0px 0px">
      <div class="u-position-relative">
        <BlScrollerbox
          class="c-scrollerbox--arrow-box c-scrollerbox--arrow-below-box c-scrollerbox--arrow-dark"
          :collection="mainBanner"
          :content-window="1"
          :scroll-offset="1"
        >
          <div
            class="ut-main-banner c-scrollerbox__item js-scrollerbox__item u-width-1"
            v-for="(item, index) in mainBanner"
            :key="`main-banner-${index}`"
          >
            <div class="c-banner-thumb__item--xlarge c-banner-thumb__item">
              <BrandPageBannerItem
                class="c-banner-thumb__img"
                :type="item.banner_type"
                :src="item.image_url"
                :url="item.url"
              />
            </div>
          </div>
        </BlScrollerbox>
      </div>
    </BlFlexItemAv>
    <BlFlexItemAv shrink="1" grow="1">
      <BlFlexItemAv
        basis="100%"
        v-for="(item, index) in secondaryBanner"
        :key="`secondary-banner-${index}`"
        shrink="1"
        :margin="`0px 0px ${index < 1 ? '12px' : '0px'} 0px`"
        class="ut-secondary-banner"
      >
        <div class="c-banner-thumb__item--small c-banner-thumb__item">
          <BrandPageBannerItem
            class="c-banner-thumb__img"
            :type="item.banner_type"
            :src="item.image_url"
            :url="item.url"
          />
        </div>
      </BlFlexItemAv>
    </BlFlexItemAv>
  </BlFlexContainerMv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import BlScrollerbox from 'shared/javascripts/components/BlScrollerbox';
import BannerMixins from 'shared/javascripts/mixins/merchant-page/banner';
import BrandPageBannerItem from '../../BrandPageBannerItem';

export default {
  name: 'BrandPageBannerType2',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlScrollerbox,
    BrandPageBannerItem,
  },
  mixins: [BannerMixins],
};
</script>

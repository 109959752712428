<template>
  <div class="o-layout u-mrgn-bottom--4">
    <div class="o-layout__item u-mrgn-bottom--3" v-for="con in conditions" :key="con.value">
      <bl-radio-button-av :label="con.name" v-model="productConditionData" :value="con.value" color="red" />
    </div>
  </div>
</template>

<script>
import { BlRadioButtonAv } from '@bukalapak/bazaar-dweb';
import FilterCondition from 'shared/javascripts/mixins/filter/condition';

export default {
  name: 'FilterProductCondition',
  components: {
    BlRadioButtonAv,
  },
  mixins: [FilterCondition],
};
</script>

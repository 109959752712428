<template>
  <div class="c-scrollerbox c-scrollerbox__wrapper">
    <div
      class="c-scrollerbox__arrow c-scrollerbox__arrow--left"
      v-bind:class="[navClass, navClassModifier]"
      v-if="infiniteLoop || canScrollPrev"
      @click="scrollPrev"
    ></div>
    <div
      class="c-scrollerbox__arrow c-scrollerbox__arrow--right"
      v-bind:class="[navClass, navClassModifier]"
      v-if="infiniteLoop || canScrollNext"
      @click="scrollNext"
    ></div>
    <div class="c-scrollerbox__clip" :class="clipClass">
      <div
        class="c-scrollerbox__content "
        :style="contentStyle"
        :class="{ 'c-scrollerbox__content--with-transition': !infiniteLoop }"
      >
        <slot></slot>
      </div>
    </div>
    <template v-if="$slots.outerSlot">
      <div class="c-scrollerbox__clip c-scrollerbox__clip--outer" :style="clipStyleOuter" :class="clipOuterClass">
        <div
          class="c-scrollerbox__content "
          :style="contentStyle"
          :class="{ 'c-scrollerbox__content--with-transition': !infiniteLoop }"
        >
          <slot name="outerSlot"></slot>
        </div>
      </div>
    </template>
    <div class="c-scrollerbox__dots" :class="[dotClass, dotClassModifier]" v-if="showDotNav">
      <span
        class="c-scrollerbox__dot u-clearfix"
        v-for="i in realItemTotal"
        :key="i"
        :class="{ 'is-active': dotActive(i) }"
        @click="moveToItem($event, i)"
        >&nbsp;</span
      >
    </div>
  </div>
</template>
<script>
import { debounce, throttle } from 'shared/javascripts/utils/function-helpers';

export default {
  name: 'v-scrollerbox',
  props: {
    scrollOffset: {
      type: Number,
      default: 1,
    },
    contentWindow: {
      type: Number,
      default: 6,
    },
    collection: {
      type: Array,
      default() {
        return [];
      },
    },
    navModifier: {
      type: Array,
      default() {
        return [];
      },
    },
    navClass: {
      type: String,
      default: '',
    },
    clipClass: {
      type: String,
      default: '',
    },
    clipStyleOuter: {
      type: Object,
      default() {
        return {};
      },
    },
    clipOuterClass: {
      type: String,
      default: '',
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    infiniteLoop: {
      // in order to use infiniteLoop, use must modify your collection
      type: Boolean, // so it contains [lastItem, firstItem, ..., lastItem, firstItem]
      default: false,
    },
    showDotNav: {
      type: Boolean,
      default: false,
    },
    dotModifier: {
      type: Array,
      default() {
        return [];
      },
    },
    dotClass: {
      type: String,
    },
    transitionDuration: {
      type: Number,
      default: 800,
    },
    panelType: {
      type: String,
      default: '',
    },
    pageFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      contentXPos: 0,
      contentOffset: 0,
      mouseEnter: false,
      lastScrollDirection: 1,
      abCheckNext: true,
      panelIndex: 0,
    };
  },
  mounted() {
    const self = this;

    window.addEventListener(
      'resize.v-scrollerbox',
      debounce(() => {
        self.repositionContent();
      }, 400),
    );

    this.$el.addEventListener('mouseenter.v-scrollerbox', () => {
      this.mouseEnter = true;
    });

    this.$el.addEventListener('mouseleave.v-scrollerbox', () => {
      this.mouseEnter = false;
    });

    if (this.infiniteLoop) {
      this.contentOffset = 1;
      this.repositionContent();
    }

    if (this.autoplay) {
      this.startAutoPlay();
    }
  },
  computed: {
    realItemTotal() {
      return this.infiniteLoop ? this.collection.length - 2 : this.collection.length;
    },
    contentStyle() {
      return {
        transform: `translateX(${this.contentXPos}px)`,
        'transition-duration': `${this.transitionDuration / 1000}s`,
      };
    },
    canScrollNext() {
      return this.canScroll(1);
    },
    canScrollPrev() {
      return this.canScroll(-1);
    },
    navClassModifier() {
      return this.navModifier
        .reduce((result, mod) => {
          const res = result;
          res.push(`c-scrollerbox__arrow--${mod}`);
          return res;
        }, [])
        .join(' ');
    },
    dotClassModifier() {
      return this.dotModifier
        .reduce((result, mod) => {
          const res = result;
          res.push(`c-scrollerbox__dots--${mod}`);
          return res;
        }, [])
        .join(' ');
    },
  },
  methods: {
    moveToItem(e, dotOrder) {
      const modifiedDotOrder = this.infiniteLoop ? dotOrder : dotOrder - 1;
      if (!e.target.classList.contains('is-active')) {
        this.lastScrollDirection = modifiedDotOrder - this.contentOffset;
        this.lastScrollDirection = this.lastScrollDirection / Math.abs(this.lastScrollDirection);
        this.contentOffset = modifiedDotOrder;
        this.repositionContent();
      }
    },
    dotActive(dotOrder) {
      const modifiedDotOrder = this.infiniteLoop ? dotOrder : dotOrder - 1;
      const asIndex = modifiedDotOrder === this.contentOffset;

      const edgingOnPrev =
        this.infiniteLoop && modifiedDotOrder === 1 && this.contentOffset === this.collection.length - 1;

      const edgingOnNext = this.infiniteLoop && modifiedDotOrder === this.realItemTotal && this.contentOffset === 0;

      return asIndex || edgingOnNext || edgingOnPrev;
    },
    startAutoPlay() {
      setInterval(() => {
        if (this.canScrollNext && !this.mouseEnter) {
          this.scroll(this.lastScrollDirection);
        }
      }, 6000);
    },
    scroll(direction) {
      if (this.infiniteLoop) {
        const lastItemOffset = this.collection.length - 1;

        if (this.contentOffset === 1 && direction < 0) {
          this.contentXPos = -this.getScrollUnit() * lastItemOffset;
          this.$el.querySelector('.c-scrollerbox__content').style['transition-duration'] = '0s';
          this.$el.querySelector('.c-scrollerbox__content').style.transform = `translateX(${this.contentXPos}px)`;
          this.contentOffset = this.collection.length - 1;
        } else if (this.contentOffset === lastItemOffset && direction > 0) {
          this.contentXPos = -this.getScrollUnit();
          this.$el.querySelector('.c-scrollerbox__content').style['transition-duration'] = '0s';
          this.$el.querySelector('.c-scrollerbox__content').style.transform = `translateX(${this.contentXPos}px)`;
          this.contentOffset = 1;
        }
      }

      setTimeout(() => {
        this.$el.querySelector('.c-scrollerbox__content').style['transition-duration'] = `${this.transitionDuration /
          1000}s`;
        if (this.canScroll(direction)) {
          const scrollUnit = this.getScrollUnit();
          const allowedScrollOffset = this.infiniteLoop || this.getAllowedScrollOffset(direction);
          this.panelIndex += direction;
          this.contentOffset = this.contentOffset + direction * allowedScrollOffset;
          this.contentXPos += direction * -1 * scrollUnit * allowedScrollOffset;
          this.$emit('scrolled', this.panelIndex);
        }
      }, 0);
    },
    scrollNext() {
      this.throttledNext =
        this.throttledNext ||
        throttle(
          () => {
            this.lastScrollDirection = 1;
            this.scroll(1);
          },
          this.transitionDuration + 100,
          { trailing: false },
        );
      this.throttledNext.call(this);
    },
    scrollPrev() {
      this.throttledPrev =
        this.throttledPrev ||
        throttle(
          () => {
            this.lastScrollDirection = -1;
            this.scroll(-1);
          },
          this.transitionDuration + 100,
          { trailing: false },
        );
      this.throttledPrev.call(this);
    },
    canScroll(direction) {
      return (
        this.infiniteLoop ||
        (() => {
          const newContentOffset = this.contentOffset + direction;
          const maxOffset = this.collection.length - this.contentWindow;

          return newContentOffset >= 0 && newContentOffset <= maxOffset;
        })()
      );
    },
    getScrollUnit() {
      const $scrollerboxItem = this.$el.querySelector('.js-scrollerbox__item');

      if ($scrollerboxItem) {
        if ($scrollerboxItem.getBoundingClientRect) {
          return $scrollerboxItem.getBoundingClientRect().width;
        }

        return $scrollerboxItem.clientWidth;
      }

      return 0;
    },
    getAllowedScrollOffset(direction) {
      if (direction > 0) {
        const remainingOffset = this.collection.length - this.contentOffset - this.contentWindow;

        return Math.min(this.scrollOffset, remainingOffset);
      }

      return Math.min(this.scrollOffset, this.contentOffset);
    },
    repositionContent() {
      this.contentXPos = -1 * this.contentOffset * this.getScrollUnit();
    },
  },
};
</script>

import NeoConfigAPI from 'shared/javascripts/api/neo';

const ageRestrictionMixin = {
  data: () => ({
    ageRestrictedStoreIds: [],
  }),
  computed: {
    isAgeRestricted() {
      return this.ageRestrictedStoreIds.includes(this.store && this.store.id);
    },
  },
  methods: {
    async getAgeRestrictedStore() {
      try {
        const { data } = await NeoConfigAPI.getNeoConfigs({ id: ['merchant-page-age-restriction'] });
        this.ageRestrictedStoreIds = (data && data[0]?.data?.['store_ids']) || [];
      } catch (_) {
        /**/
      }
    },
  },
};

export default ageRestrictionMixin;

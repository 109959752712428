<template>
  <section class="u-bg--sand-light">
    <div class="o-container o-container--responsive u-bg--sand-light">
      <div class="o-layout u-mrgn-bottom--7">
        <div class="o-layout__item">
          <!-- header -->
          <bl-panel-mv class="u-fg--black u-border-top--0 u-mrgn-bottom--2">
            <MerchantPageBanner />
            <MerchantPageInfo />
          </bl-panel-mv>
        </div>
        <client-only>
          <div
            class="o-layout__item u-mrgn-v--2"
            v-if="!isStoreInactive && store.brand_seller && isShownTemplateBanners"
          >
            <BrandPageBanner
              v-if="isBannersLoading || (banners && banners.state !== 'inactive' && banners.banners.length)"
              :type="banners.template_type"
              :banners="banners.banners"
              :loading="isBannersLoading"
              class="u-mrgn-bottom--2"
            />
            <BrandPageLabels
              v-if="isBrandLabelsLoading || (banners.template_type !== 'template_4' && brandLabels.length)"
              :brandLabels="brandLabels"
              :loading="isBrandLabelsLoading"
              @get-products="getProducts"
              class="u-mrgn-bottom--2"
            />
          </div>
        </client-only>
        <!-- close/freeze panel -->
        <div v-if="isStoreInactive" class="o-layout__item">
          <MerchantPageClosePanel />
        </div>
        <!-- product higlights -->
        <div class="o-layout__item" v-if="!isStoreInactive && isProductHighlightToggleOn && !isHighlightsLoading">
          <MerchantPageHighlightsEntry class="u-mrgn-bottom--2" v-if="isShownProductHighlightSCEntrypoint" />
          <MerchantPageHighlights class="u-mrgn-bottom--2" v-else-if="productHighlights.length > 0" />
        </div>
        <div :class="[{ 'u-3of12': !isStoreInactive }, { 'u-12of12': isStoreInactive }]" class="o-layout__item">
          <template v-if="!isStoreInactive">
            <MerchantPageEtalase :label-slug="labelSlug" class="u-mrgn-bottom--2" @get-products="getProducts" />
            <client-only>
              <MerchantPagePremiumVoucher v-if="vouchers.length" class="u-mrgn-bottom--2" />
              <MerchantPageLastOrderSchedule v-if="isShownLastOrderSchedule" class="u-mrgn-bottom--2" />
            </client-only>
          </template>
          <MerchantPageShareButtons class="u-mrgn-bottom--2" />
          <client-only>
            <div class="u-mrgn-bottom--2">
              <a class="c-link--quaternary" :href="reportStoreUrl"> Laporkan Pelanggaran </a>
            </div>
          </client-only>
          <div class="u-mrgn-bottom--2" v-if="isAdmin">
            <a class="c-link--quaternary" :href="policiesUrl"> Admin </a>
          </div>
        </div>
        <!-- product list -->
        <div v-if="!isStoreInactive" class="o-layout__item u-9of12 u-mrgn-bottom--1 u-pad-left--2">
          <MerchantPageProductList
            :label-slug="labelSlug"
            :filtered-labels="filteredLabels"
            :is-label-found="isLabelFound"
            :reload-data="reloadProduct"
            @reload="getProducts"
            @get-products="getProducts"
          />
        </div>
      </div>
      <div id="seo" class="u-clear-both u-mrgn-bottom--6 u-mrgn-top--6" v-if="brand && brand.page_description">
        <article class="c-seo" v-html="brand.page_description" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { BlPanelMv } from '@bukalapak/bazaar-dweb';
import { stores } from 'shared/javascripts/api/merchant/api-modules';

import { removeProperties } from 'shared/javascripts/utils/object-helpers';

import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';

/* eslint-disable import/extensions */
import MerchantPageClosePanel from '../components/MerchantPageClosePanel';
import MerchantPageBanner from '../components/MerchantPageBanner';
import MerchantPageInfo from '../components/MerchantPageInfo';
import MerchantPageEtalase from '../components/MerchantPageEtalase';
import MerchantPagePremiumVoucher from '../components/MerchantPagePremiumVoucher';
import MerchantPageLastOrderSchedule from '../components/MerchantPageLastOrderSchedule';
import MerchantPageShareButtons from '../components/MerchantPageShareButtons';
import MerchantPageProductList from '../components/MerchantPageProductList';
import MerchantPageHighlights from '../components/MerchantPageHighlights';
import MerchantPageHighlightsEntry from '../components/MerchantPageHighlightsEntry';
import BrandPageBanner from '../components/BrandPageBanner';
import BrandPageLabels from '../components/BrandPageLabels';

export default {
  name: 'MerchantPage',
  mixins: [StoreStateMixin],
  components: {
    BlPanelMv,
    MerchantPageClosePanel,
    MerchantPageBanner,
    MerchantPageInfo,
    MerchantPageEtalase,
    MerchantPagePremiumVoucher,
    MerchantPageLastOrderSchedule,
    MerchantPageShareButtons,
    MerchantPageProductList,
    MerchantPageHighlights,
    MerchantPageHighlightsEntry,
    BrandPageBanner,
    BrandPageLabels,
  },
  data() {
    return {
      reloadProduct: false,
    };
  },
  computed: {
    ...mapState('store', [
      'store',
      'labels',
      'filters',
      'products',
      'vouchers',
      'banners',
      'isBannersLoading',
      'brandLabels',
      'isBrandLabelsLoading',
    ]),
    ...mapState('productHighlights', ['productHighlights', 'isHighlightsLoading']),
    ...mapState('toggleFeatures', ['toggle']),
    ...mapGetters('store', ['isStoreInactive']),
    filteredLabels() {
      return this.labels.filter(label => label.slug === this.labelSlug);
    },
    labelSlug() {
      return this.$route.params.label_slug;
    },
    policiesUrl() {
      return this.store.owner ? `/policies/u/${this.store.owner.username}` : '';
    },
    reportStoreUrl() {
      let comeback;
      if (typeof window !== 'undefined') {
        comeback = `?comeback=${encodeURIComponent(window.location.href)}`;
      }
      return this.isUserLogin ? `/users/${this.store.id}/report` : `/login${comeback}`;
    },
    isLabelFound() {
      return this.filteredLabels.length > 0;
    },
    isProductHighlightToggleOn() {
      return this.toggle['seller_center/feature/product_highlights'];
    },
    isShownLastOrderSchedule() {
      return this.store.last_order_schedule && Object.keys(this.store.last_order_schedule).length;
    },
    isShownProductHighlightSCEntrypoint() {
      return !this.productHighlights.length && this.isCurrentUserStore;
    },
    isShownTemplateBanners() {
      const { banners, brandLabels, isBannersLoading, isBrandLabelsLoading } = this;
      return (
        isBannersLoading ||
        isBrandLabelsLoading ||
        !!(banners && banners.state !== 'inactive' && banners.banners.length) ||
        !!brandLabels.length
      );
    },
    hasNoVouchers() {
      return !this.vouchers.length && this.$route.query.voucher_code;
    },
    hasNoMatchingVoucher() {
      return Boolean(
        this.$route.query.voucher_code &&
          !this.vouchers.find(voucher => voucher.voucher_code === this.$route.query.voucher_code),
      );
    },
  },
  watch: {
    isProductHighlightToggleOn: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initProductHighlights();
        } else {
          this.$store.commit('productHighlights/updateLoadingHighlights', false);
        }
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        if (!this.labels || (this.labels && !this.labels.length)) {
          await this.$store.dispatch('store/GET_LABELS');
        }
        await this.initLabelSlug();
        this.$store.commit('store/updateLoadingStore', false);
        this.$store.dispatch('toggleFeatures/GET_ALL_TOGGLE');
        this.$store.dispatch('chat/RETRIEVE_LAST_SEEN', this.store.id);
        this.updateFilter();
        if (!this.isCurrentUserStore) {
          this.$store.dispatch('chat/RETRIEVE_ONLINE_STATUS', this.store.id);
        }
        if (!this.isStoreInactive) {
          if (!(this.products && this.products.length)) {
            this.getProducts();
          }
          await this.$store.dispatch('store/GET_STORE_VOUCHERS', { id: this.store.id });
          if (this.store.brand_seller) {
            this.$store.dispatch('store/GET_BANNERS', this.store.id);
            this.$store.dispatch('store/GET_BRAND_LABELS', this.store.id);
          }
          if (this.hasNoVouchers || this.hasNoMatchingVoucher) {
            this.flash('Maaf, voucher tidak ditemukan', 'error');
          }
        }
      } catch (e) {
        this.$root.notifyError(e, 'initMerchantPage', { storeId: this.store.id }, this.$options.name);
      }
    },

    async initLabelSlug() {
      const labelId = this.labelSlug && this.isLabelFound ? this.filteredLabels[0].id : null;
      await this.$store.dispatch('store/UPDATE_FILTERS', { label_id: labelId });
    },

    async initProductHighlights() {
      if (!this.productHighlights) {
        try {
          const { data } = await stores.$productHighlights.retrieveStoreProductHighlights(this.store_id.toString());
          this.$store.commit('updateProductHighlights', { data, filter: false });
        } catch (err) {
          this.$root.notifyError(err, 'getProductHighlights', { storeId: this.store.id }, this.$options.name);
        } finally {
          this.$store.commit('productHighlights/updateLoadingHighlights', false);
        }
      }
    },
    async getProducts() {
      const isChosenLabelNotFound = this.labelSlug && !this.isLabelFound;
      if (!isChosenLabelNotFound) {
        try {
          this.reloadProduct = false;
          await this.$store.dispatch('store/GET_PRODUCTS');
        } catch (err) {
          this.$root.notifyError(err, 'getProducts', { filters: this.filters }, this.$options.name);
          this.reloadProduct = true;
        }
      }
      this.$store.commit('store/updateLoadingProduct', false);
    },
    async updateFilter() {
      const filtersResult = removeProperties(this.$route.query, ['limit', 'offset', 'store_id', 'label_id', 'page']);
      if (filtersResult.couriers && !Array.isArray(filtersResult.couriers)) {
        filtersResult.couriers = [filtersResult.couriers];
      }
      await this.$store.dispatch('store/UPDATE_FILTERS', {
        ...filtersResult,
        offset:
          this.$route.query.page && this.$route.query.page > 0 ? (this.$route.query.page - 1) * this.filters.limit : 0,
      });
    },
  },
};
</script>

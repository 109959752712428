const FilterOthers = {
  props: {
    productDeal: {
      type: String,
      default: '',
    },
    productInstallment: {
      type: String,
      default: '',
    },
    productWholesale: {
      type: String,
      default: '',
    },
  },
  computed: {
    productDealData: {
      get() {
        return this.productDeal === 'true';
      },
      set(val) {
        this.$emit('product-deal-change', val ? 'true' : '');
      },
    },
    productInstallmentData: {
      get() {
        return this.productInstallment === 'true';
      },
      set(val) {
        this.$emit('product-installment-change', val ? 'true' : '');
      },
    },
    productWholesaleData: {
      get() {
        return this.productWholesale === 'true';
      },
      set(val) {
        this.$emit('product-wholesale-change', val ? 'true' : '');
      },
    },
  },
};

export default FilterOthers;

const FilterPriceRange = {
  props: {
    productPriceRange: {
      type: String,
      default: '',
    },
  },
  computed: {
    filterError() {
      return !!this.maxPrice && !!this.minPrice && this.maxPrice <= this.minPrice;
    },
    minPrice: {
      get() {
        const minPrice = this.productPriceRange.split(':')[0];
        return minPrice === '0' ? undefined : parseInt(minPrice, 10) || undefined;
      },
      set(val) {
        const stringFilter = `${val || 0}:${this.maxPrice ? this.maxPrice : 0}`;
        this.$emit('product-price-range-change', stringFilter === '0:0' ? '' : stringFilter);
      },
    },
    maxPrice: {
      get() {
        const maxPrice = this.productPriceRange.split(':').length > 1 ? this.productPriceRange.split(':')[1] : '';
        return maxPrice === '0' ? undefined : parseInt(maxPrice, 10) || undefined;
      },
      set(val) {
        const stringFilter = `${this.minPrice ? this.minPrice : 0}:${val || 0}`;
        this.$emit('product-price-range-change', stringFilter === '0:0' ? '' : stringFilter);
      },
    },
  },
  watch: {
    filterError(val) {
      this.$emit('filter-error', val);
    },
  },
};

export default FilterPriceRange;

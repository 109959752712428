<template>
  <bl-card-body-mv class="revamp-c-card--body">
    <div class="c-product-card-description">
      <a
        class="c-product-card__name js-tracker-product-link u-mrgn-top--2 u-mrgn-bottom--1 c-product-card__ellipsis"
        :class="cardType === 'productHighlight' ? 'c-product-card__ellipsis-1' : 'c-product-card__ellipsis-2'"
        :href="productUrl"
        rel="nofollow"
        :title="product.name"
        v-html="boldWords"
        @mousedown="$emit('hit-product')"
      >
      </a>
      <div
        class="c-product-rating u-mrgn-bottom--1"
        v-if="!(cardType === 'productHighlight') && !(cardType === 'feed')"
      >
        <template v-if="Object.keys(product.rating).length">
          <bl-rating-mv :value="ratingPercentage" :max-ratings="5" />
          <a class="c-product-rating__count u-fg--black u-txt--small" :href="productReviewPath" rel="nofollow">
            <span>{{ product.rating.user_count }}</span> ulasan
          </a>
        </template>
      </div>
      <div
        class="c-product-price u-txt--ellipsis"
        :class="cardType === 'productHighlight' ? 'c-product-price-highlight' : ''"
      >
        <template v-if="cardType === 'productHighlight'">
          <template v-if="hasDeal">
            <span class="u-fg--red-brand u-txt--bold">
              {{ product.price | money }}
            </span>
            <span class="u-fg--ash-dark u-txt--small u-txt--strikethrough">
              {{ product.original_price | money }}
            </span>
          </template>
          <span v-else class="u-display-block u-fg--red-brand u-txt--bold">
            {{ product.price | money }}
          </span>
        </template>
        <template v-else>
          <template v-if="hasDeal">
            <span class="u-display-block u-fg--ash-dark u-txt--small u-txt--strikethrough">
              {{ product.deal.original_price | money }}
            </span>
            <span class="u-display-block u-fg--red-brand u-txt--bold">
              {{ product.deal.discount_price | money }}
            </span>
          </template>
          <span v-else class="u-display-block u-fg--red-brand u-txt--bold c-product-price-normal">
            {{ product.price | money }}
          </span>
        </template>
      </div>
      <bl-button-av
        v-if="!isOwnProduct && !(cardType === 'feed')"
        class="u-mrgn-top--2 js-add-to-cart-btn js-track-atc-reco"
        size="small"
        block
        @click="$emit('add-to-cart')"
      >
        <bl-icon-av class="c-btn__icon" variant="add-cart" size="base" />
        Masukkan Keranjang
      </bl-button-av>
    </div>
  </bl-card-body-mv>
</template>

<script>
import { BlCardBodyMv, BlRatingMv, BlButtonAv, BlIconAv } from '@bukalapak/bazaar-dweb';

export default {
  name: 'ProductCardBody',
  components: {
    BlCardBodyMv,
    BlRatingMv,
    BlButtonAv,
    BlIconAv,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isOwnProduct: {
      type: Boolean,
      default: false,
    },
    keywords: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: '',
    },
    cardType: {
      type: String,
      default: '',
      validator(value) {
        return ['productHighlight', 'feed', ''].indexOf(value) > -1;
      },
    },
  },
  computed: {
    ratingPercentage() {
      const averageRate = this.product.rating.average_rate ? this.product.rating.average_rate : 0;
      return (averageRate / 5) * 100;
    },
    productReviewPath() {
      const loc = new URL(this.product.url || '');
      return `/reviews${loc.pathname.replace('/p/', '/')}`;
    },
    boldWords() {
      if (!this.keywords) {
        return this.product.name;
      }
      const filteredWords = this.keywords.replace(/([.?&*+^$[\]\\(){}|-])/g, '\\$1');
      const wordsToBold = filteredWords.split(' ');
      return this.product.name.replace(
        new RegExp(`(\\b)(${wordsToBold.join('|')})(\\b)`, 'ig'),
        '$1<strong>$2</strong>$3',
      );
    },
    productUrl() {
      return this.query ? `${this.product.url}?${this.query}` : this.product.url;
    },
    hasDeal() {
      const { deal } = this.product;
      if (deal && deal.applied_date && deal.expired_date) {
        const validAppliedDate = Date.parse(deal.applied_date) <= Date.now();
        const validExpiredDate = Date.parse(deal.expired_date) > Date.now();
        return validAppliedDate && validExpiredDate;
      }
      return false;
    },
  },
};
</script>

<style src="./RevampProductCardBody.style.scss" lang="scss" scoped></style>

const getNewRouteQueries = (newFilters = {}, defaultFilters = {}) => {
  const queries = Object.keys(newFilters).reduce((acc, key) => {
    const isSkippedFilters = ['limit', 'offset', 'store_id', 'label_id', 'page'].includes(key);
    const value = newFilters[key];
    const isEmptyArray = Array.isArray(value) && !value.length;

    if (!(isSkippedFilters || isEmptyArray) && value !== defaultFilters[key]) {
      acc[key] = value;
    }
    return acc;
  }, {});

  if (newFilters.limit) {
    const page = newFilters.offset / newFilters.limit + 1;
    if (page > 1) queries.page = page;
  }
  return queries;
};

export default getNewRouteQueries;

export function getShareLinks(storeName) {
  return {
    facebook: {
      name: 'Facebook',
      utm_source: 'facebook',
      icon: 'facebook',
      color: 'facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u=',
    },
    twitter: {
      name: 'Twitter',
      utm_source: 'twitter',
      icon: 'twitter',
      color: 'twitter',
      url: `https://www.twitter.com/share?text=${storeName}&url=`,
    },
    pinterest: {
      name: 'Pinterest',
      utm_source: 'pinterest',
      icon: 'pinterest',
      color: 'pinterest',
      url: 'https://www.pinterest.com/pin/create/button/?url=',
    },
    line: {
      name: 'Line',
      utm_source: 'line.me',
      icon: 'line-full',
      color: 'line',
      url: 'https://social-plugins.line.me/lineit/share?url=',
    },
  };
}

export default {
  getShareLinks,
};

<template>
  <a class="banner-image" :href="url" v-if="type === 'image'">
    <WebP :src="src" :webp="true">
      <BlImage class="ut-banner-item-image banner-image" :data-src="src" />
    </WebP>
  </a>
  <iframe
    v-else
    width="100%"
    height="100%"
    :src="embedYoutube"
    :allow="allow.join(';')"
    playerControl
    allowfullscreen
    class="ut-banner-item-iframe"
  />
</template>

<script>
import WebP from 'shared/javascripts/components/WebP';
import BlImage from 'shared/javascripts/components/BlImage';

export default {
  name: 'BrandPageBannerItem',
  components: {
    BlImage,
    WebP,
  },
  computed: {
    embedYoutube() {
      const watch = this.url.split('watch?v=');
      return `https://youtube.com/embed/${watch[1] || ''}`;
    },
  },
  props: {
    type: {
      type: String,
      default: 'image',
    },
    src: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    allow: {
      type: Array,
      default: () => ['accelerometer', 'autoplay', 'encrypted-media', 'gyroscope', 'picture-in-picture'],
    },
  },
};
</script>
<style lang="scss" scoped>
.banner-image {
  width: 100%;
  @media only screen and (min-width: 82.563em) {
    height: 100% !important;
  }
}
iframe {
  border-width: 0px !important;
}
</style>

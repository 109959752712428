import { mapState } from 'vuex';

const FilterCouriers = {
  props: {
    productCouriers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('store', ['couriers', 'isCouriersLoading']),
    productCouriersData: {
      get() {
        return this.productCouriers;
      },
      set(newValue) {
        this.$emit('product-couriers-change', newValue);
      },
    },
  }
};

export default FilterCouriers;

/**
 * @module helpers/number-helper
 */

/**
 * Return random integer in between min and max
 *
 * @example
 * getRandomInt(0, 5)
 * // => 0, 1, 2, 3, 4 or 5
 *
 * @param  {Number} min minimum possible value
 * @param  {Number} max maximum possible value
 *
 * @return {Number}
 */
export function getRandomInt(min, max) {
  if (max < min) {
    throw new Error('Max must be greater or equal than min');
  }

  // eslint-disable-next-line no-mixed-operators
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Return the positive of a number
 *
 * @example
 * toPositiveInt(-4)
 * // => 4
 *
 * @param  {Number} val
 * @return {Number}
 */
export function toPositiveInt(val) {
  if (typeof val !== 'number') {
    throw new Error('toPositiveInt must receive number as input');
  }
  return Math.abs(val);
}

/**
 * Delimit number
 *
 * @example
 * delimiter(1000000)
 * // => '1.000.000'
 *
 * @param  {Number|String} val Number to delimit
 * @return {String} Delimited number
 */
export function delimiter(val) {
  const str = `${parseInt(val, 10)}`; // convert to string
  const delimited = str.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${val < 0 ? '-' : ''}${delimited}`;
}

/**
 * Convert number to money
 *
 * @example
 * money(1000000)
 * // => 'Rp1.000.000'
 *
 * @param  {Number|String} val Number to convert
 * @return {String} Converted number in money format
 */
export function money(val) {
  const delimited = delimiter(val);
  if (delimited.charAt(0) === '-') {
    return delimited.replace(/^-/g, '-Rp');
  }
  return `Rp${delimited}`;
}

/**
 * Transform number to simple currency format
 * can't satisfy these cases below
 * 15.750 ---> 15,7rb
 * 13.456.000 ---> 13,4jt
 *
 * @example
 * simpleMoney(500000)
 * // => 'Rp500rb'
 *
 * @param  {Number} Amount required e.g. 10000
 * @param  {String} Symbol optional e.g. 'Rp'
 * @return {String} Transformed number
 */
export function simpleMoney(amount, symbol = 'Rp') {
  let absAmount = amount;
  if (amount < 0) {
    absAmount = Math.abs(Number(amount));
  }

  let parsedNumber = absAmount || 0;
  const units = ['', 'rb', 'jt', 'M', 'T'];

  let unitIndex = 0;
  while (parsedNumber >= 1000) {
    parsedNumber /= 1000;
    unitIndex += 1;
  }

  parsedNumber = parseFloat(Math.floor(parsedNumber * 10) / 10);
  if (parsedNumber === Math.floor(parsedNumber)) {
    parsedNumber = Math.floor(parsedNumber);
  }

  const simpleFormat = `${symbol}${parsedNumber.toString().replace('.', ',')}${units[unitIndex]}`;
  return `${amount < 0 ? '-' : ''}${simpleFormat}`;
}

/**
 * Convert Formated number to number string only apply positif number integer
 *
 * @example
 * delimiter('1.000.000')
 * // => '1000000'
 *
 * @param  {String} val Number to convert
 * @return {String} Positif integer number string
 */
export const toNumber = val => {
  const str = `${val}`; // convert to string
  return str.replace(/\D/g, '');
};

/**
 * Convert Formated number to add 0 before the number
 *
 * @example
 * prependZero('1')
 * // => '01'
 *
 * @param  {String} val Number to convert
 * @return {String} Positif integer number string
 */
export const prependZero = val => {
  const number = parseInt(val, 10);
  if (number < 10) {
    return `0${number.toString()}`;
  }
  return number.toString();
};

/**
 * Convert number to bytes size format
 *
 * @example
 * bytesToSize(1024)
 * // => '1KB'
 *
 * @param  {Number} val Number to convert
 * @return {String} bytes size format
 */
export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 Byte';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
};

/**
 * Check if value is string with number on it
 * example :
 * '123' //return true
 * '123a' //return false
 * 123 //return true
 */
export const isNumber = value => value % 1 === 0;

export default {
  getRandomInt,
  toPositiveInt,
  delimiter,
  money,
  toNumber,
  prependZero,
  isNumber,
};

import Stores from '@bukalapak/orchid/services/stores';
import OfficialBrandStores from '@bukalapak/orchid/services/official-brand-stores';
import TemplatePages from '@bukalapak/orchid/services/template-pages/users';
import Couriers from '@bukalapak/orchid/services/couriers';
import Carts from '@bukalapak/orchid/services/carts';
import Chat from '@bukalapak/orchid/services/chat';
import Onboardings from '@bukalapak/orchid/services/_exclusive/stores/onboardings';
import Me from '@bukalapak/orchid/services/me';

import adapter from '../request-adapter';
import interfaces from '../extra-interfaces';

const wrapSecureClientInterface = global.API && global.API.wrap;
export const extraApi = wrapSecureClientInterface ? wrapSecureClientInterface(interfaces) : {};

export const stores = new Stores(adapter);
export const brand = new OfficialBrandStores(adapter);
export const banner = new TemplatePages(adapter);
export const carts = new Carts(adapter);
export const couriers = new Couriers(adapter);
export const chat = new Chat(adapter);
export const onboardings = new Onboardings(adapter);
export const me = new Me(adapter);

export default {
  extraApi,
  stores,
  brand,
  banner,
  carts,
  couriers,
  chat,
  onboardings,
  me,
};

const MerchantPageRouteMixin = {
  computed: {
    routePath() {
      const { brand, username, id } = this.$route.params;
      if (brand) return `/${brand}`;
      if (id) return `/users/${id}`;
      return `/u/${username}`;
    },
  },
};

export default MerchantPageRouteMixin;

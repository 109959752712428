/**
 * Pick object properties
 *
 * @param obj
 * @param properties
 * @returns {{}}
 *
 * @example
 * pickProperties({ foo: 'bar', bar: 'baz' }, ['foo'])
 * // => { foo: 'bar' }
 */
const pickProperties = (obj, properties = []) => Object.keys(obj)
  .filter(k => properties.includes(k))
  .reduce((o, i) => {
    o[i] = obj[i];
    return o;
  }, {});

module.exports = {
  pickProperties,
};

import { mapState } from 'vuex';
import { trackPageviewGA, trackPageview } from 'shared/javascripts/utils/tracker';
import defaultFilters from 'shared/data/default-filters';
import getNewRouteQueries from 'shared/javascripts/utils/merchant-page/get-new-route-queries';
import MerchantPageRouteMixin from './route';

const MerchantPageLabels = {
  mixins: [MerchantPageRouteMixin],
  props: {
    labelSlug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      productListId: 'merchant-page-product-list',
    };
  },
  computed: {
    ...mapState('store', ['labels', 'isStoreLoading', 'filters']),
  },
  methods: {
    async changeLabel(newLabel) {
      if (this.labelSlug !== (newLabel ? newLabel.slug : '')) {
        const query = getNewRouteQueries({ ...this.filters, offset: 0 }, defaultFilters());
        const documentReferrer = window.location.href;
        this.$router.replace({
          path: `${this.routePath}${newLabel ? `/label/${newLabel.slug}` : ''}`,
          query,
        });
        const foundLabel = newLabel ? this.labels.find(label => label.slug === newLabel.slug) : null;
        await this.$store.dispatch('store/UPDATE_FILTERS', { label_id: foundLabel ? foundLabel.id : null, offset: 0 });
        trackPageviewGA();
        trackPageview(documentReferrer);
        this.$emit('get-products', true);
        this.scrollToProductList();
      }
    },
    scrollToProductList() {
      const productListEl = document.getElementById(this.productListId);
      const sigilHeader = document.querySelector('.sigil-header');
      const headerHeight = (sigilHeader && sigilHeader.clientHeight) || 116;
      if (productListEl) {
        window.scroll({
          top: productListEl.offsetTop - headerHeight,
          behavior: 'smooth',
        });
      }
    },
  },
};

export default MerchantPageLabels;

import chat from 'shared/javascripts/stores/merchant-dweb/chat';
import store from 'shared/javascripts/stores/merchant-dweb/store';
import productHighlights from 'shared/javascripts/stores/merchant-dweb/product-highlights';
import onboarding from 'shared/javascripts/stores/merchant-dweb/onboarding';
import toggleFeatures from 'shared/javascripts/stores/merchant-dweb/toggle-features';

import mutations from './mutations';
import actions from './actions';
import state from './state';

const createStore = Vuex =>
  new Vuex.Store({
    strict: process.env.NODE_ENV === 'development',
    actions,
    mutations,
    modules: {
      chat,
      store,
      onboarding,
      productHighlights,
      toggleFeatures,
    },
    state,
  });

export default createStore;

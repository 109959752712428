<template>
  <div>
    <div class="o-layout u-mrgn-bottom--4">
      <div class="o-layout__item u-mrgn-bottom--3">
        <bl-checkbox-av label="Diskon" v-model="productDealData" color="red" />
      </div>
      <div class="o-layout__item u-mrgn-bottom--3">
        <bl-checkbox-av label="Cicilan" v-model="productInstallmentData" color="red" />
      </div>
      <div class="o-layout__item u-mrgn-bottom--3">
        <bl-checkbox-av label="Grosir" v-model="productWholesaleData" color="red" />
      </div>
    </div>
  </div>
</template>

<script>
import { BlCheckboxAv } from '@bukalapak/bazaar-dweb';
import FilterOthers from 'shared/javascripts/mixins/filter/others';

export default {
  name: 'FilterProductOthers',
  components: {
    BlCheckboxAv,
  },
  mixins: [FilterOthers],
};
</script>

<template>
  <div class="revamp-product-loader">
    <bl-preloader-av
      :wrapper-style="{ width: '100%' }"
      loading-class="u-border-radius--2 revamp-product-loader-header"
    />
    <bl-preloader-av
      class="u-txt--normal u-mrgn-top--2 u-mrgn-bottom--1"
      :wrapper-style="{ width: '100%', height: `${isProductHighlight ? '20px' : '40px'}` }"
      loading-class="u-border-radius--2"
    />
    <bl-preloader-av
      v-if="!isProductHighlight"
      class="u-txt--normal u-mrgn-bottom--1"
      :wrapper-style="{ width: '100%' }"
      loading-class="u-border-radius--2"
    />
    <bl-preloader-av
      class="u-txt--normal u-mrgn-bottom--1"
      :wrapper-style="{ width: '100%', height: `${isProductHighlight ? '20px' : '35px'}` }"
      loading-class="u-border-radius--2"
    />
    <bl-preloader-av
      v-if="!isOwnProduct"
      class="u-txt--normal"
      :wrapper-style="{ width: '100%' }"
      loading-class="u-border-radius--2 u-mrgn-top--2 u-mrgn-bottom--1 revamp-product-loader-btn"
    />
  </div>
</template>

<script>
import { BlPreloaderAv } from '@bukalapak/bazaar-dweb';

export default {
  name: 'RevampProductCardLoader',
  components: {
    BlPreloaderAv,
  },
  props: {
    isOwnProduct: {
      type: Boolean,
      default: false,
    },
    isProductHighlight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style src="./RevampProductCardLoader.style.scss" lang="scss"></style>

<template>
  <bl-table-av size="tight" columnsWidth="equal">
    <tbody>
      <tr>
        <td class="ut-feedback-percentage">
          <bl-icon-av class="u-mrgn-right--1 u-valign-middle" duotone size="medium" variant="acc-summary" />
          <span class="u-valign-middle">Pembeli Puas</span>
        </td>
        <td class="ut-feedback-percentage">{{ feedbackPercentage }}% Puas</td>
        <td class="ut-login">
          <bl-icon-av class="u-mrgn-right--1 u-valign-middle" duotone size="medium" variant="chat" />
          <span class="u-valign-middle">Terakhir Online</span>
        </td>
        <td class="ut-login">{{ lastAppearAtWording }}</td>
        <td class="ut-join">
          <bl-icon-av class="u-mrgn-right--1 u-valign-middle" duotone size="medium" variant="subscribers" />
          <span class="u-valign-middle">Bergabung</span>
        </td>
        <td class="ut-join">{{ joinedAt }}</td>
      </tr>
      <tr>
        <td class="ut-total-feedback">
          <bl-icon-av class="u-mrgn-right--1 u-valign-middle" duotone size="medium" variant="feedback" />
          <span class="u-valign-middle">Jumlah Feedback</span>
        </td>
        <td class="ut-total-feedback">
          <bl-hyperlink-av
            class="statistik-feedback"
            variant="quaternary"
            :href="storeFeedbackUrl"
            rel="noopener nofollow"
            data-testid="ut-statistik-feedback"
            target="_blank"
          >
            {{ totalFeedback.toLocaleString() }}
          </bl-hyperlink-av>
        </td>
        <td class="ut-delivery-time">
          <bl-icon-av class="u-mrgn-right--1 u-valign-middle" duotone size="medium" variant="product-delivered" />
          <span class="u-valign-middle">Waktu Kirim Pesanan</span>
          <client-only>
            <bl-popover-av dark trigger="hover">
              <bl-icon-av variant="help" />
              <div slot="content" class="u-pad--3">
                Waktu rata rata pelapak untuk mengirim paket ke pihak ekspedisi
              </div>
            </bl-popover-av>
          </client-only>
        </td>
        <td class="ut-delivery-time">{{ store.delivery_time }}</td>
      </tr>
    </tbody>
  </bl-table-av>
</template>

<script>
import { BlTableAv, BlIconAv, BlPopoverAv, BlHyperlinkAv } from '@bukalapak/bazaar-dweb';
import StoreStatisticsMixin from 'shared/javascripts/mixins/store/statistics';

export default {
  name: 'MerchantStatistic',
  components: {
    BlTableAv,
    BlIconAv,
    BlPopoverAv,
    BlHyperlinkAv,
  },
  mixins: [StoreStatisticsMixin],
};
</script>
<style src="../MerchantPageInfo.style.scss" lang="scss" scoped></style>

<template>
  <picture>
    <source v-if="webp" :data-srcset="`${src}.webp`" type="image/webp" />
    <source :data-srcset="`${src}`" :type="`image/${ext}`" />
    <slot />
  </picture>
</template>
<script>
export default {
  name: 'WebP',
  props: {
    src: {
      type: String,
      required: true,
    },
    webp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ext() {
      return this.src && this.src.split('.').pop();
    },
  },
};
</script>

export { pickProperties } from './pick-properties';

/**
 * Check object if not empty
 *
 * @example
 * isNotEmptyObject({})
 * // => false
 *
 * @param  {Object} obj Object to check
 */
export const isNotEmptyObject = obj => Object.keys(obj).length > 0;
/**
 * Remove empty properties
 *
 * @example
 * removeEmpty({})
 * // => {}
 *
 * @param  {Object} obj Object to remove empty props
 */
export const removeEmpty = ({ object, removeEmptyString = false, removeEmptyArray = false }) => {
  const cloneObj = Object.assign({}, object);
  Object.keys(cloneObj).forEach(key => {
    if (removeEmptyString && cloneObj[key] === '') {
      delete cloneObj[key];
    }
    if (removeEmptyArray && Array.isArray(cloneObj[key]) && cloneObj[key].length === 0) {
      delete cloneObj[key];
    }
    if (cloneObj[key] === undefined || cloneObj[key] === null) {
      delete cloneObj[key];
    }
    if (cloneObj[key] && typeof cloneObj[key] === 'object') {
      removeEmpty({ object: cloneObj[key] });
    }
  });
  return cloneObj;
};

export const removeProperty = (obj, propertyName) => {
  const { [propertyName]: _, ...result } = obj;
  return result;
};

export const removeProperties = (obj, propertyNames) => {
  let result = obj;
  propertyNames.forEach(propertyName => {
    result = removeProperty(result, propertyName);
  });
  return result;
};

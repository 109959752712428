<template>
  <div class="revamp-c-voucher">
    <div class="revamp-c-voucher__desc u-pad-h--3 u-pad-top--2 u-pad-bottom--1">
      <bl-flag-mv type="default" :reverse="false" valign="top" class="u-mrgn-bottom--2">
        <bl-flag-head-av>
          <bl-icon-av duotone variant="voucher-code" size="medium" />
        </bl-flag-head-av>
        <bl-flag-body-av>
          <span v-if="voucherType === 'percentage'" class="u-txt--bold u-mrgn-bottom--1 u-txt--fair ut-voucher-discount"
            >Diskon {{ percentageSetting.percentage_value }}%</span
          >
          <span v-else class="u-txt--bold u-mrgn-bottom--1 u-txt--fair ut-voucher-discount">{{ voucherTitle }}</span>
          <p class="ut-advance-voucher-info">{{ advanceVoucherDescription }}</p>
        </bl-flag-body-av>
      </bl-flag-mv>
      <div class="u-txt--base ut-voucher-descriptions">
        <template v-if="label">
          Untuk barang-barang dalam etalase <strong>{{ label.name }}</strong>
        </template>
        <template v-else>
          Untuk semua barang
        </template>
        dari <strong>{{ storeName }}</strong> dengan minimum transaksi <strong>{{ minPurchase | money }}</strong
        >.
      </div>
    </div>
    <div class="revamp-c-voucher__promo u-pad-h--3 u-pad-bottom--2 u-pad-top--1">
      <div class="u-txt--small u-fg--ash u-pad-v--1">Kode Voucher</div>
      <div class="u-bg--sand u-border-radius--2 u-pad-h--2 u-pad-v--1">
        <span class="u-txt--bold u-txt--upcase u-txt--base ut-voucher-code">{{ code }}</span>
        <bl-popover-av
          upward
          class="u-float-right revamp-c-voucher__popover"
          size="small"
          dark
          trigger="manual"
          :show="copied"
        >
          <span class="u-txt--bold u-fg--red-brand u-txt--base u-valign-middle u-pointer" @click="copyCode">Salin</span>
          <div slot="content" class="u-pad--3 ut-voucher-copied">
            Tersalin!
          </div>
        </bl-popover-av>
      </div>
    </div>
  </div>
</template>

<script>
import { BlFlagMv, BlFlagHeadAv, BlFlagBodyAv, BlIconAv, BlPopoverAv } from '@bukalapak/bazaar-dweb';
import { copyTextToClipboard } from '../../utils/clipboard-helpers';
import { money } from '../../utils/number-helper';

export default {
  name: 'RevampVoucher',
  components: {
    BlPopoverAv,
    BlFlagMv,
    BlFlagHeadAv,
    BlFlagBodyAv,
    BlIconAv,
  },
  data() {
    return {
      copied: false,
    };
  },
  props: {
    storeName: {
      type: String,
      required: true,
    },
    label: {
      type: Object,
      required: false,
    },
    minPurchase: {
      type: Number,
      required: false,
    },
    amount: {
      type: Number,
      required: false,
    },
    code: {
      type: String,
      required: true,
    },
    voucherType: {
      type: String,
      default: '',
    },
    allowedCourier: {
      type: String,
      default: '',
    },
    percentageSetting: {
      type: Object,
      default: () => ({
        percentage_value: 0,
        maximum_discount_amount: 0,
      }),
    },
  },
  computed: {
    voucherTitle() {
      return `${this.voucherType === 'shipping' ? 'Gratis Ongkir' : 'Potongan'} ${money(this.amount)}`;
    },
    advanceVoucherDescription() {
      if (this.voucherType === 'shipping') {
        return `dengan pengiriman ${this.allowedCourier}`;
      }
      if (this.voucherType === 'percentage') {
        return `maksimal hingga ${money(this.percentageSetting.maximum_discount_amount)}`;
      }
      return '';
    },
  },
  methods: {
    copyCode() {
      if (copyTextToClipboard(this.code)) {
        this.copied = true;
        this.$emit('code-copied', this.code);
        setTimeout(() => {
          this.copied = false;
        }, 300);
      }
    },
  },
};
</script>

<style src="./RevampVoucher.style.scss" lang="scss" scoped></style>

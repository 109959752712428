import dayjs from 'dayjs';
/**
 * @module helpers/date-helpers
 */

export const day = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

export const dayShort = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];

export const daySuperShort = ['Mn', 'Sn', 'Sl', 'Rb', 'Km', 'Jm', 'Sb'];

export const workdays = ['Sn', 'Sl', 'Rb', 'Km', 'Jm'];

export const weekend = ['Mn', 'Sb'];

export const months = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des'];

const TIMEZONE_NAMES = {
  'UTC+0': 'GMT',
  'UTC+1': 'CET',
  'UTC+2': 'EET',
  'UTC+3': 'EEDT',
  'UTC+3.5': 'IRST',
  'UTC+4': 'MSD',
  'UTC+4.5': 'AFT',
  'UTC+5': 'PKT',
  'UTC+5.5': 'IST',
  'UTC+6': 'BST',
  'UTC+6.5': 'MST',
  'UTC+7': 'WIB',
  'UTC+8': 'WITA',
  'UTC+9': 'WIT',
  'UTC+9.5': 'ACST',
  'UTC+10': 'AEST',
  'UTC+10.5': 'ACDT',
  'UTC+11': 'AEDT',
  'UTC+11.5': 'NFT',
  'UTC+12': 'NZST',
  'UTC-1': 'AZOST',
  'UTC-2': 'GST',
  'UTC-3': 'BRT',
  'UTC-3.5': 'NST',
  'UTC-4': 'CLT',
  'UTC-4.5': 'VET',
  'UTC-5': 'EST',
  'UTC-6': 'CST',
  'UTC-7': 'MST',
  'UTC-8': 'PST',
  'UTC-9': 'AKST',
  'UTC-9.5': 'MIT',
  'UTC-10': 'HST',
  'UTC-11': 'SST',
  'UTC-12': 'BIT',
};
/**
 * Returns human readable timestamp format
 *
 * @example
 * humanizeTimestamp('2017-06-23T14:15:53.886+07:00', '%day% %dd% %mmm% %yyyy% - %hh%:%mi% WIB')
 * // => Jumat 23 Jun 2017 - 14:15 WIB
 *
 * @param  {String} timestamp timestamp format
 * @param  {String} format specific wanted format to humanize the timestamp (in-casesensitive)
 *
 * @return {String}
 */
export function humanizeTimestamp(timestamp, format = '%day%, %dd% %month% %yyyy%') {
  const dateTime = new Date(timestamp);
  let offset = (-1 * dateTime.getTimezoneOffset()) / 60;
  offset = `UTC${offset >= 0 ? `+${offset}` : offset}`;
  const timezone = TIMEZONE_NAMES[offset];
  const timeMapping = {
    '%hh%': `0${dateTime.getHours()}`.slice(-2),
    '%mi%': `0${dateTime.getMinutes()}`.slice(-2),
    '%ss%': `0${dateTime.getSeconds()}`.slice(-2),
    '%dd%': `0${dateTime.getDate()}`.slice(-2),
    '%d%': dateTime.getDate(),
    '%day%': day[dateTime.getDay()],
    '%mm%': `0${dateTime.getMonth() + 1}`.slice(-2),
    '%mmm%': monthShort[dateTime.getMonth()],
    '%month%': months[dateTime.getMonth()],
    '%yyyy%': dateTime.getFullYear(),
    '%yy%': dateTime
      .getFullYear()
      .toString()
      .substr(2, 2),
    '%tz%': timezone,
  };
  return format.replace(/%(.*?)%/gi, n => timeMapping[n]);
}

export function getTimeDifference(now, end, unit) {
  const nowDate = dayjs(now);
  const endDate = dayjs(end);
  return nowDate.diff(endDate, unit);
}

export function humanizeTimeAgo(date) {
  const minutes = Math.floor(getTimeDifference(new Date(), date, 'minute'));
  const hours = Math.floor(getTimeDifference(new Date(), date, 'hour'));
  const days = Math.floor(getTimeDifference(new Date(), date, 'day'));
  const weeks = Math.floor(days / 7);
  let wording = minutes < 1 ? 'Baru saja' : `${minutes} menit lalu`;
  wording = hours < 1 ? wording : `${hours} jam lalu`;
  wording = days < 1 ? wording : `Kemarin`;
  wording = days < 2 ? wording : `${days} hari lalu`;
  wording = days < 7 ? wording : `Minggu lalu`;
  wording = days < 15 ? wording : `${weeks} minggu lalu`;
  wording = weeks < 4 ? wording : `Lebih dari sebulan lalu`;
  return wording;
}

export default {
  day,
  dayShort,
  daySuperShort,
  workdays,
  weekend,
  months,
  monthShort,
  humanizeTimestamp,
  humanizeTimeAgo,
  getTimeDifference,
};

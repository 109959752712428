<template>
  <div>
    <div class="o-layout u-mrgn-bottom--4">
      <div class="o-layout__item">
        <bl-field-mv>
          <bl-field-row-mv>
            <bl-field-label-av>Harga Minimal</bl-field-label-av>
            <bl-input-group-mv prefix-type="text" prefix-context="Rp" class="u-mrgn-top--1">
              <bl-text-field-mv
                slot="input"
                v-model="minPrice"
                name="minPrice"
                :formatter="delimiter"
                :is-error="filterError"
              />
            </bl-input-group-mv>
            <bl-field-error-av v-if="filterError">
              Harga Minimal harus lebih kecil dari Harga Maksimal
            </bl-field-error-av>
          </bl-field-row-mv>
        </bl-field-mv>
      </div>
      <div class="o-layout__item">
        <bl-field-mv>
          <bl-field-row-mv>
            <bl-field-label-av>Harga Maksimal</bl-field-label-av>
            <bl-input-group-mv prefix-type="text" prefix-context="Rp" class="u-mrgn-top--1">
              <bl-text-field-mv
                slot="input"
                v-model="maxPrice"
                name="maxPrice"
                :formatter="delimiter"
                :is-error="filterError"
              />
            </bl-input-group-mv>
            <bl-field-error-av v-if="filterError">
              Harga Maksimal harus lebih besar dari Harga Minimal
            </bl-field-error-av>
          </bl-field-row-mv>
        </bl-field-mv>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BlInputGroupMv,
  BlTextFieldMv,
  BlFieldMv,
  BlFieldRowMv,
  BlFieldLabelAv,
  BlFieldErrorAv,
} from '@bukalapak/bazaar-dweb';
import { delimiter, toNumber } from 'shared/javascripts/utils/number-helper';
import FilterPriceRange from 'shared/javascripts/mixins/filter/price-range';

export default {
  name: 'FilterProductPriceRange',
  components: {
    BlInputGroupMv,
    BlTextFieldMv,
    BlFieldMv,
    BlFieldRowMv,
    BlFieldLabelAv,
    BlFieldErrorAv,
  },
  mixins: [FilterPriceRange],
  data() {
    return {
      delimiter: {
        format: delimiter,
        deformat: toNumber,
      },
    };
  },
};
</script>

<template>
  <div>
    <div class="o-layout u-mrgn-bottom--4" :key="componentKey">
      <div class="o-layout__item">
        <bl-text-field-mv placeholder="Cari kota" v-model="filterCity" />
      </div>
    </div>
    <div class="o-layout u-mrgn-bottom--4">
      <div class="o-layout__item u-mrgn-bottom--3" v-for="city in cities" :key="city.title">
        <div class="u-txt--bold u-mrgn-bottom--3">
          {{ city.title }}
        </div>
        <div
          class="u-mrgn-bottom--3"
          v-for="cov in city.coverage.filter(cov => cov.name.toUpperCase().indexOf(filterCity.toUpperCase()) !== -1)"
          :key="cov.id"
        >
          <span class="ut-filter-free-shipping__radio-button" @click="checkValue(cov.id, $event)">
            <bl-radio-button-av :label="cov.name" v-model="productFreeShippingData" :value="`${cov.id}`" color="red" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BlTextFieldMv, BlRadioButtonAv } from '@bukalapak/bazaar-dweb';
import FilterFreeShipping from 'shared/javascripts/mixins/filter/free-shipping';

export default {
  name: 'FilterProductFreeShipping',
  components: {
    BlTextFieldMv,
    BlRadioButtonAv,
  },
  mixins: [FilterFreeShipping],
  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    checkValue(val, $event) {
      if ($event.target.className === 'c-inp__inner-lbl c-inp__inner-lbl--red') {
        if (val === parseInt(this.productFreeShippingData, 10)) {
          this.$emit('product-free-shipping-change', '');
          this.componentKey += 1;
          $event.stopPropagation();
          $event.preventDefault();
        }
      }
    },
  },
};
</script>

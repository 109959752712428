<template>
  <bl-panel-mv :id="productListId" class="revamp-product-list">
    <client-only>
      <ModalFilterProducts :filters="filters" @apply-product-filter="applyFilters" />
    </client-only>
    <bl-panel-head-mv>
      <bl-panel-title-av class="revamp-product-list-header">
        <!-- product list search -->
        <bl-input-group-mv
          prefix-type="icon"
          prefix-icon="search"
          postfix-type="button"
          class="revamp-product-list-search"
        >
          <bl-text-field-mv
            slot="input"
            :placeholder="store.name ? `Cari di ${store.name}` : ''"
            v-model="keywords"
            @onEnter="searchKeywords"
          />
          <bl-button-av v-if="keywords" shape="pill" @click="clearKeywords" slot="postfix">
            <bl-icon-av variant="close" size="base" />
          </bl-button-av>
        </bl-input-group-mv>
        <!-- product list sort button -->
        <client-only>
          <bl-popover-av position="right" class="c-pl__sort" trigger="click">
            <bl-button-av class="revamp-product-list-header-btn">
              <bl-icon-av variant="sort" size="base" duotone />
              <span :class="{ 'u-txt--bold': chosenSort }" class="c-btn__text u-txt--normal">{{
                chosenSort ? chosenSort.name : 'Urutkan'
              }}</span>
            </bl-button-av>
            <bl-list-ui-mv link slot="content">
              <bl-list-item-av
                v-for="sort in listOfSorts"
                :key="sort.name"
                class="u-no-border--bottom"
                @click="updateSort(sort)"
              >
                <span class="c-list-ui__link u-txt--small">
                  {{ sort.name }}
                  <bl-icon-av v-if="filters.sort === sort.value" class="u-float-right" variant="done" />
                </span>
              </bl-list-item-av>
            </bl-list-ui-mv>
          </bl-popover-av>
          <!-- product list filter button -->
          <bl-button-av @click="openFilterModal" class="revamp-product-list-header-btn ut-filter-btn">
            <bl-icon-av variant="sort" size="base" duotone />
            <span :class="chosenFilter > 0 ? '' : 'u-txt--normal'" class="c-btn__text">
              Filter{{ chosenFilter > 0 ? ` (${chosenFilter})` : '' }}
            </span>
          </bl-button-av>
        </client-only>
      </bl-panel-title-av>
    </bl-panel-head-mv>
    <!-- product list -->
    <bl-panel-body-mv class="u-pad--3">
      <p v-if="!isStoreLoading" class="revamp-product-list-title u-mrgn-bottom--1" v-html="productListTitle" />
      <div class="o-layout o-layout--responsive">
        <template v-if="isProductsLoading">
          <div
            class="o-layout__item item-product u-width-1of4 u-mrgn-v--2"
            :key="`loading-${index}`"
            v-for="index in filters.limit"
          >
            <RevampProductCardLoader :is-own-product="isCurrentUserStore" />
          </div>
        </template>
        <template v-else>
          <!-- chosen etalase is not found -->
          <template v-if="labelSlug && !isLabelFound">
            <div class="u-align-center">
              <div class="u-display-inline-block">
                <div class="u-mrgn-bottom--3">
                  <img class="u-display-inline u-width--200" :src="getAssetUrl('images/ill_barang_not_found.png')" />
                </div>
                <div class="u-mrgn-bottom--2">
                  Etalase Tidak Ditemukan
                </div>
              </div>
            </div>
          </template>
          <!-- fail fetching -->
          <template v-else-if="reloadData">
            <reload-data :margin="50" @reload="$emit('reload')" />
          </template>
          <!-- success fetching, product is empty -->
          <template v-else-if="!reloadData && !products.length">
            <div class="u-align-center">
              <div class="u-display-inline-block">
                <div class="u-mrgn-bottom--3">
                  <img
                    class="u-display-inline u-width--200"
                    :src="
                      filters.keywords || chosenFilter > 0
                        ? getAssetUrl('images/ill_barang_not_found.png')
                        : getAssetUrl('images/ill_no_barang.png')
                    "
                  />
                </div>
                <div class="u-mrgn-bottom--2">
                  {{ filters.keywords || chosenFilter > 0 ? 'Barang Tidak Ditemukan' : 'Tidak Ada Barang' }}
                </div>
              </div>
            </div>
          </template>
          <!-- success fetching -->
          <template v-else>
            <div
              class="o-layout__item item-product u-width-1of4 u-mrgn-v--2"
              :key="product.id"
              v-for="product in products"
            >
              <RevampProductCard
                :keywords="filters.keywords"
                :is-own-product="isCurrentUserStore"
                :product="product"
                :query="queryProductCard"
                @add-to-cart="addToCart(product)"
              />
            </div>
          </template>
        </template>
      </div>
      <bl-ghostblock-pagination-mv
        v-if="ghostblockPagination.total"
        class="u-mrgn-v--8"
        :pagination="ghostblockPagination"
        :url-params="paginateSearchParamsStr"
        :keyword="ghostblockKeyword"
        aria-next-label="Halaman selanjutnya"
        aria-previous-label="Halaman sebelumnya"
        aria-page-label="Halaman"
        @pageChange="changePage"
      />
    </bl-panel-body-mv>
  </bl-panel-mv>
</template>

<script>
import { mapState } from 'vuex';
import {
  BlButtonAv,
  BlPanelMv,
  BlPanelHeadMv,
  BlPanelTitleAv,
  BlPanelBodyMv,
  BlInputGroupMv,
  BlTextFieldMv,
  BlPopoverAv,
  BlListUiMv,
  BlListItemAv,
  BlIconAv,
} from '@bukalapak/bazaar-dweb';

import { parseErrorMessage } from 'shared/javascripts/utils/api-helpers';
import { sanitizeText } from 'shared/javascripts/utils/string-helpers';
import defaultFilters from 'shared/data/default-filters';
import getNewRouteQueries from 'shared/javascripts/utils/merchant-page/get-new-route-queries';

import AssetsMixin from 'shared/javascripts/mixins/assets';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { trackAddToCart, trackPageviewGA, trackPageview } from 'shared/javascripts/utils/tracker';

import BlGhostblockPaginationMv from 'shared/javascripts/components/BlGhostblockPaginationMv';
import RevampProductCard from 'shared/javascripts/components/RevampProductCard';
import RevampProductCardLoader from 'shared/javascripts/components/RevampProductCardLoader';
import ReloadData from 'shared/javascripts/components/ReloadData';
import ModalFilterProducts from '../ModalFilterProducts';

import sortData from './data/sorts.json';

export default {
  name: 'MerchantPageProductList',
  mixins: [AssetsMixin, StoreStateMixin],
  components: {
    BlButtonAv,
    BlPanelMv,
    BlPanelHeadMv,
    BlPanelTitleAv,
    BlPanelBodyMv,
    BlInputGroupMv,
    BlTextFieldMv,
    BlPopoverAv,
    BlListUiMv,
    BlListItemAv,
    BlIconAv,
    BlGhostblockPaginationMv,
    RevampProductCard,
    RevampProductCardLoader,
    ReloadData,
    ModalFilterProducts,
  },
  props: {
    reloadData: {
      type: Boolean,
      default: false,
    },
    isLabelFound: {
      type: Boolean,
      default: false,
    },
    labelSlug: {
      type: String,
      default: '',
    },
    filteredLabels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      keywords: this.$route.query.keywords,
      page: this.$route.query.page,
      productListId: 'merchant-page-product-list',
      paginateSearchParamsStr: this.getParamsStr(),
    };
  },
  computed: {
    ...mapState('store', ['store', 'products', 'filters', 'productsMeta', 'isProductsLoading', 'isStoreLoading']),
    chosenSort() {
      return this.listOfSorts.find(sort => this.filters.sort === sort.value);
    },
    chosenFilter() {
      const defFilters = defaultFilters();
      const checks = [
        this.filters.price_range !== defFilters.price_range,
        this.filters.condition !== defFilters.condition,
        this.filters.rating !== defFilters.rating,
        this.filters.free_shipping_provinces !== defFilters.free_shipping_provinces,
        this.filters.couriers.length !== defFilters.couriers.length,
        this.filters.deal !== defFilters.deal,
        this.filters.installment !== defFilters.installment,
        this.filters.wholesale !== defFilters.wholesale,
      ];
      return checks.filter(check => check).length;
    },
    listOfSorts() {
      const isRelevanceSortApplicable = sort => !!this.keywords && sort.value === '';
      return sortData.filter(sort => sort.value !== '' || isRelevanceSortApplicable(sort));
    },
    productListTitle() {
      const isChosenLabelNotFound = this.labelSlug && !this.isLabelFound;
      const isLabelFilterApplied = this.labelSlug && this.isLabelFound;
      let title = '<strong>Semua Barang</strong>';
      if (this.filters.keywords) {
        title = `Hasil pencarian untuk "<strong>${sanitizeText(this.filters.keywords)}</strong>"`;
        title = isLabelFilterApplied
          ? `${title} di Etalase "<strong>${sanitizeText(this.filteredLabels[0].name)}</strong>"...`
          : `${title}...`;
      } else if (isLabelFilterApplied) {
        title = `Barang Etalase "<strong>${sanitizeText(this.filteredLabels[0].name)}</strong>"`;
      } else if (isChosenLabelNotFound) {
        title = `Etalase "<strong>${sanitizeText(this.labelSlug)}</strong>" Tidak Ditemukan`;
      }
      return title;
    },
    queryProductCard() {
      if (this.$root.isSSR) return '';
      const productOwner = this.store.brand_seller ? 'brand_seller' : 'normal_seller';
      const from = this.store.brand_seller ? 'brand-page' : 'product_owner';
      return `from=${from}&product_owner=${productOwner}`;
    },
    ghostblockKeyword() {
      return `produk di Lapak ${this.store.name}`;
    },
    ghostblockPagination() {
      const { limit, offset } = this.filters;
      const { total } = this.productsMeta;
      const currentPage = offset / limit + 1;
      const totalPages = Math.ceil(total / limit);

      return {
        currentPage,
        limit,
        offset,
        total,
        totalPages,
      };
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.paginateSearchParamsStr = this.getParamsStr();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async addToCart(product) {
      let successAddToCart = false;
      const { items } = window.STORE && window.STORE.state.cart;
      const itemCart = items && items.find(o => o.stuff && o.stuff.id && o.stuff.id === product.sku_id);
      const quantity = itemCart ? itemCart.quantity + product.min_quantity : product.min_quantity;
      try {
        await window.STORE.dispatch('cart/addOrUpdateProductToCart', {
          quantity,
          product,
        });
        if (itemCart) {
          this.flash(`${product.min_quantity} ${product.name} berhasil dimasukan ke dalam keranjang`, 'success');
        }
        successAddToCart = true;
      } catch (e) {
        const errMessage = parseErrorMessage(e) || 'Terjadi kesalahan pada server';
        this.flash(errMessage, 'error');
      }

      if (successAddToCart) {
        const { data: cart } = await this.$store.dispatch('store/RETRIEVE_CART', {
          seller_ids: [this.store.id],
          identity: this.userIdentity,
        });
        const cartItem = cart.items.find(e => e.stuff.product_id === product.id);
        trackAddToCart({
          cart,
          cartItem,
          product,
          merchantId: this.store.id,
          totalFeedback: this.store.reviews.negative + this.store.reviews.positive,
          referrerEvent: 'merchant-page/product-list',
        });
      }
    },
    getParamsStr() {
      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        params.delete('page');
        return params.toString();
      }
      return '';
    },
    applyFilters() {
      const query = getNewRouteQueries(this.filters, defaultFilters());
      this.$router.replace({ query });
      trackPageviewGA();

      const documentReferrer = window.location.href;
      trackPageview(documentReferrer);
      this.$emit('get-products', true);
    },

    async changePage(pagination) {
      let offset = this.filters.limit;

      if (typeof pagination === 'number') {
        offset = (pagination - 1) * this.filters.limit;
      } else if (pagination && typeof pagination.offset === 'number') {
        ({ offset } = pagination);
      }

      await this.$store.dispatch('store/UPDATE_FILTERS', { offset });
      this.scrollToProductList();
      this.applyFilters();
    },
    clearKeywords() {
      this.keywords = '';
      if (this.filters.keywords) {
        this.searchKeywords();
      }
    },
    openFilterModal() {
      const elHeader = global.document.getElementsByTagName('header');
      if (elHeader.length > 0) {
        elHeader[0].classList.toggle('c-header--sticky', true);
      }
      this.$store.dispatch('store/GET_COURIERS');
      this.$BLRemodal.show('modal-filter-products');
    },
    scrollToProductList() {
      const productListEl = global.document.getElementById(this.productListId);
      const sigilHeader = global.document.querySelector('.sigil-header');
      const headerHeight = (sigilHeader && sigilHeader.clientHeight) || 116;
      window.scroll({
        top: productListEl.offsetTop - headerHeight,
        behavior: 'smooth',
      });
    },
    async searchKeywords() {
      await this.$store.dispatch('store/UPDATE_FILTERS', { keywords: this.keywords, offset: 0 });
      this.applyFilters();
    },
    async updateSort(sort) {
      await this.$store.dispatch('store/UPDATE_FILTERS', { sort: sort.value, offset: 0 });
      this.applyFilters();
    },
  },
};
</script>

<style src="./MerchantPageProductList.style.scss" lang="scss" scoped></style>

<template>
  <nav v-if="paginationExists" class="c-ghostblock-pagination">
    <div class="c-ghostblock-pagination__main">
      <ul class="c-ghostblock-pagination__list">
        <li v-if="paginationItems.prevPage.enabled">
          <span
            role="button"
            class="c-ghostblock-pagination__previous"
            @click.prevent="handlePageItemClick(paginationItems.prevPage.pageNumber)"
            :aria-label="ariaPreviousLabel"
          >
            <bl-icon-av name="ico_drop_down_minor" color="#333" />
          </span>
        </li>
        <template v-for="page in paginationItems.pages">
          <li v-if="page.gap" :key="`gap-${page.position}`">
            <span class="c-ghostblock-pagination__ellipsis">&hellip;</span>
          </li>
          <li
            v-else-if="page.current"
            :key="page.ariaLabel ? `main-${page.pageNumber}` : `ghost-${page.pageNumber}`"
            class="has-page-active"
          >
            <span class="c-ghostblock-pagination__link is-current" aria-current="true" :aria-label="page.ariaLabel">
              {{ page.pageNumber }}
            </span>
          </li>
          <li v-else :key="`main-${page.pageNumber}`">
            <a
              v-if="page.pageUrl"
              class="c-ghostblock-pagination__link"
              rel="nofollow"
              :href="page.pageUrl"
              :aria-label="page.ariaLabel"
              @click.prevent="handlePageItemClick(page.pageNumber)"
            >
              {{ page.pageNumber }}
            </a>
            <span v-else class="c-ghostblock-pagination__link is-disabled" :aria-label="page.ariaLabel">
              {{ page.pageNumber }}
            </span>
          </li>
        </template>
        <li v-if="paginationItems.nextPage.enabled">
          <span
            role="button"
            class="c-ghostblock-pagination__next"
            @click.prevent="handlePageItemClick(paginationItems.nextPage.pageNumber)"
            :aria-label="ariaNextLabel"
          >
            <bl-icon-av name="ico_drop_down_minor" color="#333" />
          </span>
        </li>
      </ul>
    </div>
    <template v-if="ghostItems.pages.length > 0">
      <span class="c-ghostblock-pagination__info">
        {{ ghostItems.label }}
      </span>
      <div class="c-ghostblock-pagination__sub">
        <ul class="c-ghostblock-pagination__list">
          <li v-for="page in ghostItems.pages" :key="`sub-${page.pageNumber}`">
            <a
              v-if="page.pageUrl"
              class="c-ghostblock-pagination__link"
              rel="nofollow"
              :href="page.pageUrl"
              :aria-label="page.ariaLabel"
              @click.prevent="handleGhostItemClick(page.pageNumber)"
            >
              {{ page.pageNumber }}
            </a>
          </li>
        </ul>
      </div>
    </template>
  </nav>
</template>

<script>
import { BlIconAv } from '@bukalapak/bazaar-visual';

export default {
  name: 'BlGhostblockPaginationMv',
  components: {
    BlIconAv,
  },
  props: {
    /**
     * Pagination Config
     * `return {
          currentPage: 1,
          limit: 10,
          offset: 0,
          total: 30,
          totalPages: 0,
        }`
     */
    pagination: {
      type: Object,
      default: () => ({
        currentPage: 1,
        limit: 10,
        offset: 0,
        total: 30,
        totalPages: 0,
      }),
    },
    /**
     * Url Parameter
     */
    urlParams: {
      type: String,
      default: '',
    },
    /**
     * Whether the pagination is multi strategy
     * `true , false`
     */
    isMultiStrategy: {
      type: Boolean,
      default: false,
    },
    /**
     * Accessibility label for the next page link.
     */
    ariaNextLabel: {
      type: String,
      default: '',
    },
    /**
     * Accessibility label for the previous page link.
     */
    ariaPreviousLabel: {
      type: String,
      default: '',
    },
    /**
     * Accessibility label for the page link. If passed, this text will be prepended to the number of the page.
     */
    ariaPageLabel: {
      type: String,
      default: 'page',
    },
    /**
     * Keyword used for others label.
     */
    keyword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isGhostItemClicked: false,
    };
  },
  computed: {
    currentPage() {
      return this.pagination.currentPage;
    },
    paginationWindow() {
      /** pagination items to show after & before current page */
      return this.currentPage <= 8 ? 4 : 3;
    },
    urlParamSerialized() {
      return this.urlParams ? `&${this.urlParams}` : '';
    },
    paginationExists() {
      return Math.ceil(this.pagination.total / this.pagination.limit) >= 2;
    },
    isNearEnd() {
      return this.totalPages > 10 && this.currentPage >= 8 && this.totalPages - this.currentPage <= 5;
    },
    isLastCurrentGhosts() {
      return this.isGhostItemClicked && this.totalPages - this.currentPage > 1;
    },
    disableLastPage() {
      if ((this.totalPages === 12 || this.totalPages === 13) && this.currentPage === 8) {
        return true;
      }
      return !(this.totalPages === 10 || this.totalPages <= 9 || (!this.isLastCurrentGhosts && this.isNearEnd));
    },
    totalPages() {
      return this.isMultiStrategy
        ? this.pagination.totalPages
        : Math.ceil(this.pagination.total / this.pagination.limit);
    },
    lowerBound() {
      if (
        this.isGhostItemClicked ||
        this.totalPages <= 10 ||
        (this.totalPages > 10 && this.currentPage > 5 && this.currentPage <= 8)
      ) {
        return 1;
      }
      if (this.totalPages === 10 && this.currentPage >= 7) {
        return 3;
      }
      if (this.totalPages === 12 && this.currentPage >= 8) {
        return 4;
      }
      if (this.isNearEnd) {
        return this.currentPage - (8 - (this.totalPages - this.currentPage));
      }
      return Math.max(1, this.currentPage - this.paginationWindow);
    },
    upperBound() {
      if (this.isGhostItemClicked) {
        return this.currentPage + this.paginationWindow;
      }
      if (this.totalPages <= 9) {
        return this.totalPages;
      }
      if (this.totalPages > 10 && this.currentPage > 5 && this.currentPage <= 8) {
        return 9;
      }
      return Math.min(this.totalPages, this.currentPage + this.paginationWindow);
    },
    numPartitions() {
      if (
        this.isGhostItemClicked ||
        (this.totalPages <= 9 && this.currentPage >= 6) ||
        (this.totalPages === 12 && this.currentPage > 8)
      ) {
        return this.paginationWindow * 2 + this.paginationWindow;
      }
      if (this.totalPages === 10) {
        return 9;
      }
      let add = 1;
      if (this.totalPages === 12 || this.totalPages === 13) {
        if (this.currentPage === 8) {
          add = 1;
        } else if (this.currentPage >= 9) {
          add = 2;
        }
      } else if (!(this.totalPages === 10 && this.currentPage <= 5)) {
        if (this.isGhostItemClicked) {
          add = 3;
        } else if (this.isNearEnd) {
          add = 2;
        }
      }
      return this.paginationWindow * 2 + add;
    },
    paginationItems /* istanbul ignore next */() {
      const pages = [];
      const prevPage = {};
      const nextPage = {};

      if (this.totalPages >= 2) {
        let lastPageNumber = 0;

        for (let count = 0; count < this.numPartitions; count += 1) {
          const pageNumber = this.lowerBound + count;

          if (pageNumber < this.totalPages) {
            lastPageNumber = pageNumber;
            pages[count] = {
              pageNumber,
              pageUrl: `?page=${pageNumber}${this.urlParamSerialized}`,
              current: this.currentPage === pageNumber,
              ariaLabel: `${this.ariaPageLabel} ${pageNumber}`,
            };
          }
        }

        if (this.lowerBound >= 2) {
          const firstPage = {
            pageNumber: 1,
            pageUrl: `?page=1${this.urlParamSerialized}`,
            current: false,
          };

          if (this.lowerBound > 2) {
            const pageGap = {
              gap: true,
              position: 'before',
            };

            pages.unshift(pageGap);
          }

          pages.unshift(firstPage);
        }

        if (this.isGhostItemClicked) {
          pages.push({
            gap: true,
            position: 'current',
          });
          pages.push({
            pageNumber: this.currentPage,
            pageUrl: `?page=${this.currentPage}${this.urlParamSerialized}`,
            current: true,
          });
        }

        if (
          this.totalPages > 10 &&
          (this.isLastCurrentGhosts ||
            ((this.totalPages === 12 || this.totalPages === 13) && this.currentPage === 8) ||
            (this.upperBound < this.totalPages - 1 && lastPageNumber < this.totalPages && !this.isNearEnd))
        ) {
          const pageGap = {
            gap: true,
            position: 'after',
          };

          pages.push(pageGap);
        }

        if (!this.isGhostItemClicked || (this.isGhostItemClicked && this.currentPage !== this.totalPages)) {
          const lastPage = {
            pageNumber: this.totalPages,
            pageUrl: this.disableLastPage ? '' : `?page=${this.totalPages}${this.urlParamSerialized}`,
            current: this.disableLastPage ? false : this.currentPage === this.totalPages,
          };
          pages.push(lastPage);
        }

        if (this.currentPage > 1) {
          prevPage.enabled = true;
          prevPage.pageUrl = `?page=${this.currentPage - 1}${this.urlParamSerialized}`;
          prevPage.pageNumber = this.currentPage - 1;
        }

        if (this.currentPage < this.totalPages) {
          nextPage.enabled = true;
          nextPage.pageUrl = `?page=${this.currentPage + 1}${this.urlParamSerialized}`;
          nextPage.pageNumber = this.currentPage + 1;
        }
      }

      return {
        prevPage,
        pages,
        nextPage,
      };
    },
    ghostItems() {
      let label = '';
      const pages = [];

      if (!this.isGhostItemClicked && this.totalPages > 10 && this.currentPage <= 9) {
        label = `Cari ${this.keyword ? `${this.keyword.trim()} ` : ''}di halaman lainnya`;

        for (let count = this.currentPage * 10 + 1; count <= this.currentPage * 10 + 10; count += 1) {
          if (count <= this.totalPages) {
            pages.push({
              pageNumber: count,
              pageUrl: `?page=${count}${this.urlParamSerialized}`,
              ariaLabel: `${this.ariaPageLabel} ${count}`,
            });
          }
        }
      }

      return {
        label,
        pages,
      };
    },
  },
  watch: {
    urlParams() {
      this.isGhostItemClicked = false;
    },
  },
  methods: {
    /**
     * Event click after user clicked.
     *
     * @type function
     * @event pageChange
     */
    handlePageItemClick(newPage) {
      this.isGhostItemClicked = false;
      this.$emit('pageChange', newPage);
    },
    handleGhostItemClick(newPage) {
      this.isGhostItemClicked = true;
      this.$emit('pageChange', newPage);
    },
  },
};
</script>

<style src="./BlGhostblockPaginationMv.style.scss" lang="scss" scoped></style>

<template>
  <bl-panel-mv>
    <bl-panel-body-mv class="u-align-center u-fg--black">
      <template v-if="isStoreFreezed">
        <img
          class="ut-illustration u-margin-auto u-mrgn-top--2"
          :src="getAssetUrl('images/tutup_dibekukan-mdpi.png')"
        />
        <div class="u-mrgn-top--4 u-txt--base u-txt--bold">Lapak Dibekukan</div>
        <template v-if="isCurrentUserStore">
          <div class="u-mrgn-top--2">Akunmu dibekukan oleh tim Policy Bukalapak.</div>
          <bl-button-av
            v-if="isStoreEligibleForSelfUnfreeze"
            has-spinner
            :activated="isUnfreezing"
            @click="selfUnfreeze"
            class="u-txt--bold u-mrgn-top--4 u-mrgn-bottom--1"
            data-testid="ut-unfreeze-button"
            >Buka Akun Saya</bl-button-av
          >
          <a v-else data-testid="ut-more-info-button" href="/bantuan/pelanggaran/akun-disalahgunakan/akun-dinonaktifkan"
            ><bl-button-av class="u-txt--bold u-mrgn-top--4 u-mrgn-bottom--1">Info Lebih Lanjut</bl-button-av></a
          >
        </template>
        <template v-else>
          <div class="u-mrgn-top--2">Lapak dibekukan oleh tim Policy Bukalapak.</div>
          <a data-testid="ut-home-button" href="/"
            ><bl-button-av class="u-txt--bold u-mrgn-top--4 u-mrgn-bottom--1">Kembali ke Home</bl-button-av></a
          >
        </template>
      </template>
      <template v-else>
        <img class="ut-illustration u-margin-auto u-mrgn-top--2" :src="getAssetUrl('images/tutup_lapak-mdpi.png')" />
        <template v-if="isCurrentUserStore">
          <div class="u-mrgn-top--4 u-txt--base u-txt--bold">Kamu Sedang Menutup Lapakmu</div>
          <div class="u-mrgn-top--2">Lapak tutup hingga {{ storeOpenDate }}.</div>
          <a data-testid="ut-open-lapak-button" :href="sellerCenterSettingsUrl" target="_blank"
            ><bl-button-av class="u-txt--bold u-mrgn-top--4 u-mrgn-bottom--1">Buka Lagi Lapakmu</bl-button-av></a
          >
        </template>
        <template v-else>
          <div class="u-mrgn-top--4 u-txt--base u-txt--bold">Kami Sedang Tutup 😄</div>
          <div class="u-mrgn-top--2">
            Terima kasih sudah berkunjung. Kamu bisa kembali berbelanja pada {{ storeOpenDate }}. Sampai ketemu!
          </div>
          <a href="/" data-testid="ut-home-button"
            ><bl-button-av class="u-txt--bold u-mrgn-top--4 u-mrgn-bottom--1">Kembali ke Home</bl-button-av></a
          >
        </template>
      </template>
    </bl-panel-body-mv>
  </bl-panel-mv>
</template>

<script>
import { humanizeTimestamp } from 'shared/javascripts/utils/date-helpers';
import { BlButtonAv, BlPanelMv, BlPanelBodyMv } from '@bukalapak/bazaar-dweb';
import AssetsMixin from 'shared/javascripts/mixins/assets';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'MerchantPageClosePanel',
  components: {
    BlButtonAv,
    BlPanelMv,
    BlPanelBodyMv,
  },
  mixins: [AssetsMixin, StoreStateMixin],
  data() {
    return {
      isUnfreezing: false,
    };
  },
  computed: {
    ...mapState('store', ['store']),
    ...mapGetters('store', ['isStoreFreezed', 'isStoreEligibleForSelfUnfreeze']),
    storeOpenDate() {
      return humanizeTimestamp(this.store.closing.reopen_date, '%dd%/%mm%/%yy%');
    },
    sellerCenterSettingsUrl() {
      return `${process.env.SELLER_CENTER_URL}/settings/store/edit/close`;
    },
  },
  methods: {
    async selfUnfreeze() {
      this.isUnfreezing = true;
      const payload = {
        id: this.store.id,
        data: {
          state: 'unfreeze',
          state_options: {
            reason: 'others',
            notes: 'self unfreeze from merchant page',
          },
        },
      };
      try {
        const res = await this.$store.dispatch('store/UPDATE_STORE_STATUS', payload);
        this.isUnfreezing = false;
        if (res.meta.http_status === 200 && typeof window !== 'undefined') {
          window.location.reload();
        }
      } catch (e) {
        this.$root.notifyError(e, 'selfUnfreeze', payload, this.$options.name);
        this.isUnfreezing = false;
        this.flash('Terjadi Kesalahan pada server', 'error');
      }
    },
  },
};
</script>

const URL = '/_exclusive/info/toggle-features';
const generateToggleAgregatePayload = listToggle => {
  const aggregate = {};
  listToggle.forEach(list => {
    aggregate[list] = {
      method: 'GET',
      path: `/_exclusive/info/toggle-features?feature=${list}`,
    };
  });

  return {
    aggregate,
  };
};

export default {
  async getAllToggleFeatures() {
    const res = await this.get(`${URL}/collections`, 'store');
    return res;
  },

  async getListToggle(data) {
    const listToggle = generateToggleAgregatePayload(data);
    const res = await this.post(`/aggregate`, 'store', { data: listToggle });
    return res;
  },

  async getToggleStatus(data) {
    const listToggle = generateToggleAgregatePayload(data);
    const res = await this.get(`${URL}`, 'store', { data: listToggle });
    return res;
  },
};

<template>
  <div class="u-align-center">
    <div class="u-display-inline-block" :style="componentStyles">
      <template v-if="isFlagMode">
        <bl-flag-mv>
          <bl-flag-head-av>
            <img :src="getAssetUrl('images/ill_terjadikesalahan.png')" style="max-width: 130px;" />
          </bl-flag-head-av>
          <bl-flag-body-av>
            <div class="u-mrgn-bottom--2 u-align-left">
              Ups, sepertinya terjadi kesalahan saat memuat data
            </div>
            <div class="u-align-left">
              <bl-button-av @click="$emit('reload')">
                Muat Ulang
              </bl-button-av>
            </div>
          </bl-flag-body-av>
        </bl-flag-mv>
      </template>
      <template v-else>
        <div class="u-mrgn-bottom--3">
          <img
            class="u-display-inline"
            :src="getAssetUrl('images/ill_terjadikesalahan.png')"
            style="max-width: 130px;"
          />
        </div>
        <div class="u-mrgn-bottom--2">
          Ups, sepertinya terjadi kesalahan saat memuat data
        </div>
        <div>
          <bl-button-av @click="$emit('reload')">
            Muat Ulang
          </bl-button-av>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { BlFlagMv, BlFlagHeadAv, BlFlagBodyAv, BlButtonAv } from '@bukalapak/bazaar-dweb';
import AssetsMixin from 'shared/javascripts/mixins/assets';

export default {
  name: 'ReloadData',
  mixins: [AssetsMixin],
  components: {
    BlFlagMv,
    BlFlagHeadAv,
    BlFlagBodyAv,
    BlButtonAv,
  },
  props: {
    margin: {
      type: Number,
      default: 0,
    },
    isFlagMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentStyles() {
      return {
        'margin-top': `${this.margin}px!important`,
        'margin-bottom': `${this.margin}px!important`,
      };
    },
  },
};
</script>

<style src="./ReloadData.style.scss" lang="scss" scoped></style>

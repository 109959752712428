<template>
  <BlFlexContainerMv direction="column">
    <BlFlexItemAv class="section" basis="100%">
      <div class="u-position-relative">
        <BlScrollerbox
          class="c-scrollerbox--arrow-box c-scrollerbox--arrow-below-box c-scrollerbox--arrow-dark"
          :collection="mainBanner"
          :content-window="1"
          :scroll-offset="1"
        >
          <div
            class="ut-main-banner c-scrollerbox__item js-scrollerbox__item u-width-1"
            v-for="(item, index) in mainBanner"
            :key="`main-banner-${index}`"
          >
            <div class="c-banner-thumb__item c-banner-thumb__item--samsung">
              <BrandPageBannerItem
                class="c-banner-thumb__img"
                :type="item.banner_type"
                :src="item.image_url"
                :url="item.url"
              />
            </div>
          </div>
        </BlScrollerbox>
      </div>
    </BlFlexItemAv>
    <BrandPageBannerType4Category class="section" :brandLabels="brandLabels" :loading="isBrandLabelsLoading" />
    <BrandPageBannerType4Highlight
      class="section"
      v-for="(item, index) in samsungHighlight"
      :key="`banner-highlight-${index}`"
      :labelId="item.id"
      :name="item.name"
      :image="item.slug"
    />
  </BlFlexContainerMv>
</template>

<script>
import { mapState } from 'vuex';
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import BlScrollerbox from 'shared/javascripts/components/BlScrollerbox';
import BannerMixins from 'shared/javascripts/mixins/merchant-page/banner';
import BrandPageBannerItem from '../../BrandPageBannerItem';
import BrandPageBannerType4Highlight from './BrandPageBannerType4Highlight';
import BrandPageBannerType4Category from './BrandPageBannerType4Category';

const highlights = ['audio', 'lemaries', 'mobile', 'monitors', 'tablets', 'tv', 'wearables'];

export default {
  name: 'BrandPageBannerType4',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlScrollerbox,
    BrandPageBannerItem,
    BrandPageBannerType4Category,
    BrandPageBannerType4Highlight,
  },
  mixins: [BannerMixins],
  computed: {
    ...mapState('store', ['brandLabels', 'isBrandLabelsLoading']),
    samsungHighlight() {
      return this.brandLabels.filter(item => highlights.includes(item.slug));
    },
  },
};
</script>

<style lang="scss">
.c-banner-nav--samsung {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 16px;

  > div:not(:last-child) {
    margin-right: 8px;
  }

  &__item {
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: #333;
    border: 2px solid #333;
    border-radius: 50%;
    cursor: pointer;

    &.tns-nav-active {
      background-color: #d71149;
    }
  }
}

.c-banner-thumb__item--samsung {
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  align-items: center;
  background-color: #333;
  height: 380px;

  @media only screen and (min-width: 82.563em) {
    height: 438px;
  }

  iframe,
  img {
    width: 100%;
  }
}
</style>

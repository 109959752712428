export function debounce(func, wait, immediate) {
  let timeout = null;
  let result = null;
  const later = (context, args) => {
    timeout = null;
    if (args) {
      result = func.apply(context, args);
    }
  };
  const debounced = () => {
    let isCallNow = null;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (timeout) {
      clearTimeout(timeout);
    }
    if (immediate) {
      isCallNow = !timeout;
      timeout = setTimeout(later, wait);
      if (isCallNow) {
        result = func.apply(this, args);
      }
    } else {
      timeout = setTimeout(later, wait, this, args);
    }
    return result;
  };
  debounced.cancel = () => {
    clearTimeout(timeout);
    timeout = null;
  };
  return debounced;
}

export function throttle(func, wait, options = {}) {
  let timeout = null;
  let context = null;
  let args = null;
  let result = null;
  let previous = 0;
  const later = () => {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) {
      context = null;
      args = null;
    }
  };
  const throttled = () => {
    const now = Date.now();
    if (!previous && options.leading === false) {
      previous = now;
    }
    const remaining = wait - (now - previous);
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) {
        context = null;
        args = null;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
  throttled.cancel = () => {
    clearTimeout(timeout);
    previous = 0;
    timeout = null;
    context = null;
    args = null;
  };
  return throttled;
}

export default {
  debounce,
  throttle
}

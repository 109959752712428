import wrapSecureClientInterface from 'lib/secure-client';

export function getUrl(path = '') {
  const $elSecureClient = document.querySelector('.js-secure-client');
  let apiUrl = global.APP ? global.APP.apiURL : null;
  if (!apiUrl && $elSecureClient) {
    ({ apiUrl } = $elSecureClient.dataset);
  }
  return `${apiUrl}${path}`;
}

export const parseErrorMessage = error => {
  const { response } = error;

  if (
    response &&
    response.data &&
    response.data.errors &&
    Array.isArray(response.data.errors) &&
    response.data.errors.length > 0
  ) {
    const { message } = response.data.errors[0];
    return message || '';
  }

  return '';
};

export const wrap = (global.API && global.API.wrap) || wrapSecureClientInterface;

export default {
  wrap,
  getUrl,
  parseErrorMessage,
};

<template>
  <bl-panel-mv class="revamp-product-highlights">
    <bl-panel-head-mv class="rph-header">
      <div class="o-layout">
        <div class="o-layout__item" :class="isShowProductHighlightEntry ? 'u-width-3of4' : 'u-width-1'">
          <ul class="rph-list">
            <li
              v-for="item in productHighlights"
              :key="item.id"
              :class="selectedHighlights === item ? 'active' : ''"
              class="rph-list-item"
              @click="setSelectedPH(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="o-layout__item u-align-right u-pad--2 u-width-1of4" v-if="isShowProductHighlightEntry">
          <bl-button-av class="ut-ph-entrypoint" size="small" :href="sellerCenterPHSettingUrl"
            >Atur Barang Unggulan</bl-button-av
          >
        </div>
      </div>
    </bl-panel-head-mv>
    <bl-panel-body-mv>
      <template v-if="isHighlightsLoading || isStoreLoading">
        <BlScrollerbox
          class="c-scrollerbox--arrow-box c-scrollerbox--arrow-below-box c-scrollerbox--arrow-dark"
          :content_window="6"
          clip-class="o-layout"
        >
          <div
            class="o-layout__item rph-product-card u-mrgn-v--2 c-scrollerbox__item js-scrollerbox__item"
            :key="index"
            v-for="index in 7"
          >
            <revamp-product-card-loader is-product-highlight :is-own-product="isCurrentUserStore" />
          </div>
        </BlScrollerbox>
      </template>
      <template v-else-if="selectedHighlights && selectedHighlights.products">
        <BlScrollerbox
          class="c-scrollerbox--arrow-box c-scrollerbox--arrow-below-box c-scrollerbox--arrow-dark"
          :collection="selectedHighlights.products"
          :content_window="6"
          :key="`phsection-${selectedHighlights.id}`"
          clip-class="o-layout"
        >
          <div
            v-for="(product, idx) in selectedHighlights.products"
            :key="`phitem-${idx}-${selectedHighlights.name}`"
            class="o-layout__item rph-product-card u-mrgn-v--2 c-scrollerbox__item js-scrollerbox__item"
          >
            <revamp-product-card
              card-type="productHighlight"
              :is-own-product="isCurrentUserStore"
              :product="product"
              :query="queryProductCard"
              @hit-product="sendTrackerHitProductHighlight(product, idx, selectedHighlights.name)"
              @add-to-cart="addToCart(product)"
            />
          </div>
        </BlScrollerbox>
      </template>
    </bl-panel-body-mv>
  </bl-panel-mv>
</template>

<script>
import { mapState } from 'vuex';
import { BlPanelMv, BlPanelHeadMv, BlPanelBodyMv, BlButtonAv } from '@bukalapak/bazaar-dweb';

import { trackProductHighlight, trackAddToCart } from 'shared/javascripts/utils/tracker';
import { parseErrorMessage } from 'shared/javascripts/utils/api-helpers';

import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';

import RevampProductCard from 'shared/javascripts/components/RevampProductCard';
import BlScrollerbox from 'shared/javascripts/components/BlScrollerbox';
import RevampProductCardLoader from 'shared/javascripts/components/RevampProductCardLoader';

export default {
  name: 'MerchantPageHighlights',
  components: {
    BlPanelMv,
    BlPanelHeadMv,
    BlPanelBodyMv,
    BlButtonAv,
    BlScrollerbox,
    RevampProductCard,
    RevampProductCardLoader,
  },
  mixins: [StoreStateMixin],
  computed: {
    ...mapState('store', ['store', 'feedbacks', 'products', 'isStoreLoading']),
    ...mapState('productHighlights', ['productHighlights', 'selectedHighlights', 'isHighlightsLoading']),
    ...mapState('toggleFeatures', ['toggle']),
    sellerCenterPHSettingUrl() {
      return `${process.env.SELLER_CENTER_URL}/promotions/product-highlight`;
    },
    isShowProductHighlightEntry() {
      return this.isCurrentUserStore && this.toggle['seller_center/feature/product_highlights'];
    },
    queryProductCard() {
      if (this.$root.isSSR) return '';
      const productOwner = this.store.brand_seller ? 'brand_seller' : 'normal_seller';
      return `from=product_owner_product_highlights&product_owner=${productOwner}`;
    },
  },
  methods: {
    async addToCart(product) {
      let successAddToCart = false;
      const { items } = window.STORE && window.STORE.state.cart;
      const itemCart = items && items.find(o => o.stuff && o.stuff.id && o.stuff.id === product.sku_id);
      const quantity = itemCart ? itemCart.quantity + product.min_quantity : product.min_quantity;
      try {
        await window.STORE.dispatch('cart/addOrUpdateProductToCart', {
          quantity,
          product,
        });
        if (itemCart) {
          this.flash(`${product.min_quantity} ${product.name} berhasil dimasukan ke dalam keranjang`, 'success');
        }
        successAddToCart = true;
      } catch (e) {
        const errMessage = parseErrorMessage(e) || 'Terjadi kesalahan pada server';
        this.flash(errMessage, 'error');
      }

      if (successAddToCart) {
        const { data: cart } = await this.$store.dispatch('store/RETRIEVE_CART', {
          seller_ids: [this.store.id],
          identity: this.userIdentity,
        });
        const cartItem = cart.items.find(e => e.stuff.product_id === product.id);
        trackAddToCart({
          cart,
          cartItem,
          product,
          merchantId: this.store.id,
          totalFeedback: this.feedbacks.meta.total,
          referrerEvent: 'merchant-page/product-highlight',
        });
      }
    },
    setSelectedPH(item) {
      this.$store.dispatch('productHighlights/SET_SELECTED_PH', item);
    },
    async sendTrackerHitProductHighlight(product, position, sectionName) {
      trackProductHighlight({
        merchantId: this.store.id,
        sectionName,
        productId: product.id,
        position,
        userId: (global.USER && global.USER.id36) || '',
      });
    },
  },
};
</script>

<style src="./MerchantPageHighlights.style.scss" lang="scss" scoped></style>

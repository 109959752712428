const HB = () => {
  let instance;

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line
    const Honeybadger = require('honeybadger-js');
    instance = Honeybadger;
    instance.configure({
      apiKey: process.env.HB_API_KEY,
      environment: process.env.NODE_ENV,
      onerror: process.env.NODE_ENV !== 'development',
      async: true,
      debug: process.env.NODE_ENV === 'development',
    });
  }
  return instance;
};

export const errorHandler = ({ err, component, action, fingerprint = 'spica', context }) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error({
      err,
      component,
      action,
      fingerprint,
      context,
    });
    return;
  }
  const isServerError = err.meta && err.meta.http_status > 499 && err.meta.http_status < 600;
  if (!err.meta || isServerError) {
    const instance = new HB();
    const user = (window && window.blGlobal && window.blGlobal.user) || (window && window.USER);
    const userId = user ? { user_id: user.id } : { user_id: '0' };
    const contextPayload = Object.assign(userId, context);

    let message;
    switch (err.constructor) {
      case String:
        message = err;
        break;
      case Object:
        message = JSON.stringify(err);
        break;
      default:
        message = err.toString();
    }

    const options = {
      name: `${component} from ${fingerprint}`,
      message,
      action: action || '',
      context: contextPayload || {},
    };

    instance.notify(err, options);
  }
};

const HoneyBagderNotify = {};

HoneyBagderNotify.install = Vue => {
  /**
   * Utilities for send error to honeybadger with style
   * @param {string} param.err Error object
   * @param {string} param.component Error component name
   * @param {string} param.action Method component name
   * @param {string} param.fingerprint A unique fingerprint, used to customize grouping of errors in Honeybadger.
   * @param {object} param.context Another context object like user_id, tags etc.
   *
   * @example
   * this.$hbNotify({
   *  err,
   *  component: 'ProductListTable',
   *  action: 'setFilter',
   *  fingerprint: 'spica/merchant_page_dweb',
   *  context: {user_id: this.user_id}
   * })
   */

  Vue.prototype.$hbNotify = errorHandler;
};

export default HoneyBagderNotify;

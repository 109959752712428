import conditions from '../data/conditions';

const conditionsValidValue = conditions.map(e => e.value);
const FilterCondition = {
  props: {
    productCondition: {
      type: String,
      default: '',
      validator: value => conditionsValidValue.indexOf(value) !== -1,
    },
  },
  data() {
    return {
      conditions,
    };
  },
  computed: {
    productConditionData: {
      get() {
        return this.productCondition;
      },
      set(val) {
        this.$emit('product-condition-change', val);
      },
    },
  },
};

export default FilterCondition;

<template>
  <bl-card-mv class="revamp-c-card">
    <revamp-product-card-head
      :card-type="cardType"
      :product="product"
      :query="query"
      @hit-product="$emit('hit-product')"
    />
    <revamp-product-card-body
      :product="product"
      :is-own-product="isOwnProduct"
      :card-type="cardType"
      :keywords="keywords"
      :query="query"
      @hit-product="$emit('hit-product')"
      @add-to-cart="$emit('add-to-cart')"
    />
  </bl-card-mv>
</template>

<script>
import { BlCardMv } from '@bukalapak/bazaar-dweb';
import RevampProductCardBody from './RevampProductCardBody';
import RevampProductCardHead from './RevampProductCardHead';

export default {
  name: 'RevampProductCard',
  components: {
    BlCardMv,
    RevampProductCardBody,
    RevampProductCardHead,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isOwnProduct: {
      type: Boolean,
      default: false,
    },
    cardType: {
      type: String,
      default: '',
      validator(value) {
        return ['productHighlight', 'feed', ''].indexOf(value) > -1;
      },
    },
    keywords: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: '',
    },
  },
};
</script>

<style src="./RevampProductCard.style.scss" lang="scss" scoped></style>

<template>
  <BlPanelMv class="u-pad--4">
    <div class="c-panel-samsung--head">
      <img class="u-mrgn-auto" :src="getAssetUrl('images/samsung/category.svg')" />
    </div>
    <BlFlexContainerMv direction="row" wrap="wrap" v-if="!loading">
      <BlFlexItemAv
        basis="25%"
        shrink="1"
        padding="6px"
        v-for="(item, index) in brandLabels"
        :key="`labels-category-${index}`"
      >
        <a :href="`${routePath}/label/${item.slug}`" @click.prevent="changeLabel(item)">
          <img class="c-panel-samsung--label ut-samsung-labels" :src="item.image.desktop_url" />
        </a>
      </BlFlexItemAv>
    </BlFlexContainerMv>
    <BlFlexContainerMv direction="row" wrap="wrap" v-else>
      <BlFlexItemAv basis="25%" shrink="1" v-for="index in 8" :key="`loading-${index}`" padding="6px">
        <BlPreloaderAv loading-class="u-height--130 u-width-1 ut-samsung-labels--loading" />
      </BlFlexItemAv>
    </BlFlexContainerMv>
  </BlPanelMv>
</template>

<script>
import { BlPreloaderAv, BlPanelMv } from '@bukalapak/bazaar-dweb';
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import FilterLabelsMixins from 'shared/javascripts/mixins/merchant-page/labels';
import AssetsMixin from 'shared/javascripts/mixins/assets';

export default {
  name: 'BrandPageBannerType4Category',
  components: {
    BlPanelMv,
    BlFlexContainerMv,
    BlFlexItemAv,
    BlPreloaderAv,
  },
  mixins: [AssetsMixin, FilterLabelsMixins],
  props: {
    brandLabels: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.c-panel-samsung {
  &--head {
    padding: 16px 0;
    text-align: center;
  }

  &--label {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #fff;
    transition: all 0.3s;

    &:hover {
      border-color: #d71149;
    }
  }
}
</style>

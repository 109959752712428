import { stores, banner, brand, couriers, carts } from 'shared/javascripts/api/merchant/api-modules';
import { removeEmpty } from 'shared/javascripts/utils/object-helpers';
import defaultFilters from 'shared/data/default-filters';

const initialState = () => ({
  isStoreLoading: true,
  store: {
    owner: {
      unfreezing: {},
    },
    closing: {},
  },
  brand: {},
  products: [],
  productsMeta: {},
  isProductsLoading: true,
  isBrandLabelsLoading: true,
  labels: [],
  brandLabels: [],
  filters: defaultFilters(),
  vouchers: [],
  couriers: [],
  feedbacks: {
    meta: {},
  },
  isCouriersLoading: true,
  cartItems: [],
  banners: {},
  isBannersLoading: true,
});

const getters = {
  isStoreFreezed: state => state.store.owner.unfreezing.permanent_frozen || state.store.owner.unfreezing.frozen,
  isStoreEligibleForSelfUnfreeze: state => state.store.owner.unfreezing.eligible,
  isStoreInactive: state =>
    state.store.closing.closed || state.store.owner.unfreezing.frozen || state.store.owner.unfreezing.permanent_frozen,
};

const mutations = {
  updateStore: (state, { store, labels }) => {
    state.store = store;
    state.labels = labels;
  },
  updateProducts: (state, { data, meta }) => {
    state.products = data;
    state.productsMeta = meta;
    state.isProductsLoading = false;
  },
  updateFilters: (state, payload) => {
    state.filters = {
      ...state.filters,
      ...payload,
    };
  },
  updateLoadingProduct: (state, isLoading) => {
    state.isProductsLoading = isLoading;
  },
  updateLoadingStore: (state, isLoading) => {
    state.isStoreLoading = isLoading;
  },
  updateStoreVouchers: (state, payload) => {
    state.vouchers = payload;
  },
  updateLoadingCouriers: (state, isLoading) => {
    state.isCouriersLoading = isLoading;
  },
  updateCouriers: (state, { data }) => {
    state.couriers = data;
    state.isCouriersLoading = false;
  },
  updateStoreFeedbacks: (state, data) => {
    state.feedbacks = data;
    state.isStoreFeedbacksLoading = false;
  },
  appendProducts: (state, { data, meta }) => {
    state.products.push(...data);
    state.productsMeta = meta;
  },
  updateCartItems: (state, { items }) => {
    state.cartItems = items;
  },
  updateBanners: (state, data) => {
    state.banners = data;
    state.isBannersLoading = false;
  },
  updateBrandLabels: (state, data) => {
    state.brandLabels = data;
    state.isBrandLabelsLoading = false;
  },
  updateBrand: (state, data) => {
    state.brand = data;
  },
};

const actions = {
  GET_STORE: async ({ commit }, storeId) => {
    const { data: store } = await stores.retrieveStore(storeId);
    const { data: labels } = await stores.$labels.retrieveStoreSLabels(storeId, { has_product: true });
    commit('updateStore', { store, labels });
  },
  GET_BRAND: async ({ commit }, storeId) => {
    const { data } = await brand.retrieveOfficialBrandStore(storeId);
    commit('updateBrand', data);
  },
  GET_STORE_PROFILE: async ({ state, commit }, username) => {
    const { labels } = state;
    const { data: store } = await stores.$profile.retrieveStoreByUsername(username);
    commit('updateStore', { store, labels });
  },
  GET_LABELS: async ({ state, commit }) => {
    const { store } = state;
    const { data: labels } = await stores.$labels.retrieveStoreSLabels(store.id, { has_product: true });
    commit('updateStore', { store, labels });
  },
  GET_PRODUCTS: async ({ commit, state }, moreProduct) => {
    commit('updateLoadingProduct', !moreProduct);
    const filters = removeEmpty({
      object: state.filters,
      removeEmptyString: true,
      removeEmptyArray: true,
    });

    const response = await stores.$products.retrieveMerchantPageProducts(state.store.id, { queryParams: filters });

    if (response) {
      const { data, meta } = response;
      const commitProducts = moreProduct ? 'appendProducts' : 'updateProducts';
      commit(commitProducts, { data, meta });
    }
  },
  UPDATE_FILTERS: ({ commit }, filters) => {
    commit('updateFilters', filters);
  },
  GET_STORE_VOUCHERS: async ({ commit }, { id }) => {
    const { data } = await stores.$premiumVouchers.retrieveStorePremiumVouchers(id);
    commit('updateStoreVouchers', data);
  },
  GET_COURIERS: async ({ commit, state }) => {
    if (!state.couriers.length) {
      commit('updateLoadingCouriers', true);
      const { data, meta } = await couriers.retrieveAllCouriers();
      commit('updateCouriers', { data, meta });
    }
  },
  UPDATE_STORE_STATUS: async (_, payload) => {
    const response = await stores.updateStore(payload.id, { data: payload.data });
    return response;
  },
  GET_STORE_FEEDBACKS: async ({ commit, state }, query) => {
    state.isStoreFeedbacksLoading = true;
    const data = await stores.$feedbacks.retrieveStoreFeedbacks(state.store.id, { queryParams: query });
    commit('updateStoreFeedbacks', data);
  },
  ADD_TO_CART: async (_, { query, identity }) => {
    const data = {
      identity,
      ...query,
    };
    const response = await carts.$items.addProductToCart({
      data,
    });
    return response;
  },
  RETRIEVE_CART: async ({ commit }, query) => {
    const response = await carts.retrieveCart({
      queryParams: query,
    });
    commit('updateCartItems', response.data);
    return response;
  },
  GET_BANNERS: async ({ commit }, id) => {
    const response = await banner.retrieveTemplateSeller(id);
    commit('updateBanners', response.data);
  },
  GET_BRAND_LABELS: async ({ commit }, id) => {
    const response = await banner.$labels.retrieveTemplateLabels(id);
    commit('updateBrandLabels', response.data);
  },
  GET_PRODUCTS_BY_ID: (_, { id, query }) =>
    stores.$products.retrieveMerchantPageProducts(id, { queryParams: query }, false),
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

<template>
  <bl-panel-head-mv class="u-pad--0 merchant-page__banner-container">
    <ul class="c-breadcrumb u-width-1 u-pad--4 u-position-absolute u-txt--base">
      <li class="c-breadcrumb__item">
        <a class="c-breadcrumb__link" href="/" rel="nofollow" data-testid="ut-breadcrumb-home">
          Home
        </a>
      </li>
      <li v-if="store.brand_seller" class="c-breadcrumb__item">
        <a class="c-breadcrumb__link" href="/bukamall/">
          BukaMall
        </a>
      </li>
      <li class="c-breadcrumb__item is-active">
        <span class="c-breadcrumb__link">
          {{ storeName }}
        </span>
      </li>
    </ul>
    <div class="merchant-page__banner-icon u-position-absolute u-mrgn--4">
      <a
        v-if="isCurrentUserStore"
        class="ut-merchant-banner__ubah-header-button"
        data-testid="ut-edit-header-button"
        target="_blank"
        :href="sellerCenterHeaderSettingUrl"
      >
        <bl-button-av size="small">
          Ubah foto header
        </bl-button-av>
      </a>
    </div>
    <img
      :alt="store.name"
      class="merchant-page__banner-img u-height-1 u-width-1"
      data-testid="ut-banner-img"
      onError="this.onerror=null;this.src='https://s0.bukalapak.com/images/header-lapak-default.jpg';"
      :src="storeBanner"
    />
  </bl-panel-head-mv>
</template>

<script>
import { BlButtonAv, BlPanelHeadMv } from '@bukalapak/bazaar-dweb';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { mapState } from 'vuex';

export default {
  name: 'MerchantBanner',
  components: {
    BlButtonAv,
    BlPanelHeadMv,
  },
  mixins: [StoreStateMixin],
  computed: {
    ...mapState('store', ['store']),
    storeBanner() {
      return this.store.header_image.url || '';
    },
    storeName() {
      return (this.brand && this.brand.name) || this.store.name;
    },
    sellerCenterHeaderSettingUrl() {
      return `${process.env.SELLER_CENTER_URL}/settings/store/edit/information`;
    },
  },
};
</script>
<style src="./MerchantPageBanner.style.scss" lang="scss" scoped></style>

<template>
  <Modal
    name="modal-filter-products"
    title="Filter Barang"
    size="large"
    has-footer
    class="modal-filter"
    @opened="modalOpened"
  >
    <div class="u-bg--sand-light u-display-flex">
      <bl-tab-mv :active-tab-name.sync="activeTabName" class="u-width-1" vertical>
        <bl-tab-nav-mv>
          <bl-tab-nav-item-av v-for="(tab, index) in tabs" :name="tab.name" :key="index" item>
            <div class="c-tab__ttl">
              <span class="c-ttl--width u-txt--ellipsis u-txt--ellipsis-1">
                {{ tab.name }}
              </span>
              <bl-icon-av
                v-if="isFilterNotDefault(tab)"
                variant="check-circle"
                class="u-fg--red-brand u-mrgn-right--2"
                style="margin-top: 4px;"
              />
            </div>
          </bl-tab-nav-item-av>
        </bl-tab-nav-mv>
        <bl-tab-content-mv name="Rentang Harga">
          <filter-price-range
            @product-price-range-change="filtersData.price_range = $event"
            @filter-error="isNotValid = $event"
            :product-price-range="filtersData.price_range"
          />
        </bl-tab-content-mv>
        <bl-tab-content-mv name="Kondisi Barang">
          <filter-condition
            :product-condition="filtersData.condition"
            @product-condition-change="filtersData.condition = $event"
          />
        </bl-tab-content-mv>
        <bl-tab-content-mv name="Rating">
          <filter-rating @product-rating-change="filtersData.rating = $event" :product-rating="filtersData.rating" />
        </bl-tab-content-mv>
        <bl-tab-content-mv name="Gratis Ongkir">
          <filter-free-shipping
            @product-free-shipping-change="filtersData.free_shipping_provinces = $event"
            :product-free-shipping="`${filtersData.free_shipping_provinces}`"
          />
        </bl-tab-content-mv>
        <bl-tab-content-mv name="Jasa Pengiriman">
          <filter-couriers
            :product-couriers="filtersData.couriers"
            :couriers-keywords="couriersKeywords"
            @product-couriers-change="filtersData.couriers = $event"
            @couriers-keywords-change="couriersKeywords = $event"
          />
        </bl-tab-content-mv>
        <bl-tab-content-mv name="Lainnya">
          <filter-others
            :product-deal="filtersData.deal"
            :product-installment="filtersData.installment"
            :product-wholesale="filtersData.wholesale"
            @product-deal-change="filtersData.deal = $event"
            @product-installment-change="filtersData.installment = $event"
            @product-wholesale-change="filtersData.wholesale = $event"
          />
        </bl-tab-content-mv>
      </bl-tab-mv>
    </div>
    <div slot="footer">
      <div class="footer-action">
        <div class="u-visibility-hidden"></div>
        <div>
          <bl-button-av class="u-mrgn-right--1" @click="reset">
            Reset
          </bl-button-av>
          <bl-button-av color="red" @click="apply" :disabled="isNotValid">
            Terapkan
          </bl-button-av>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex';
import { isNumber } from 'shared/javascripts/utils/number-helper';
import { BlButtonAv, BlIconAv, BlTabMv, BlTabContentMv, BlTabNavMv, BlTabNavItemAv } from '@bukalapak/bazaar-dweb';
import tabs from 'shared/javascripts/mixins/filter/data/tabs.json';
import FilterCouriers from './components/FilterCouriers';
import FilterRating from './components/FilterRating';
import FilterCondition from './components/FilterCondition';
import FilterOthers from './components/FilterOthers';
import FilterPriceRange from './components/FilterPriceRange';
import FilterFreeShipping from './components/FilterFreeShipping';

export default {
  name: 'ModalFilterProducts',
  components: {
    BlButtonAv,
    BlIconAv,
    BlTabMv,
    BlTabContentMv,
    BlTabNavItemAv,
    BlTabNavMv,
    FilterCouriers,
    FilterRating,
    FilterCondition,
    FilterOthers,
    FilterPriceRange,
    FilterFreeShipping,
  },
  data() {
    return {
      tabs,
      activeTabName: 'Rentang Harga',
      couriersKeywords: '',
      filtersData: {},
      isNotValid: false,
    };
  },
  computed: {
    ...mapState('store', ['filters']),
  },
  methods: {
    isFilterNotDefault(tab) {
      const { filterAttribute } = tab;
      if (Array.isArray(filterAttribute)) {
        return filterAttribute.some(attribute => {
          const value = this.filtersData[attribute];
          return value !== '';
        });
      }
      const value = this.filtersData[filterAttribute];
      return Array.isArray(value) ? value.length > 0 : value !== '';
    },
    modalOpened() {
      this.cloneFiltersData();
    },
    cloneFiltersData() {
      const newData = JSON.parse(JSON.stringify(this.filters));
      Object.keys(newData).forEach(attributeName => {
        const value = newData[attributeName];
        if (typeof value === 'string' && value !== '' && isNumber(value)) {
          newData[attributeName] = parseInt(value, 10);
        }
      });
      this.filtersData = newData;
    },
    reset() {
      this.filtersData.price_range = '';
      this.filtersData.condition = '';
      this.filtersData.rating = '';
      this.filtersData.free_shipping_provinces = '';
      this.filtersData.couriers = [];
      this.filtersData.deal = '';
      this.filtersData.installment = '';
      this.filtersData.wholesale = '';
      this.couriersKeywords = '';
    },
    async apply() {
      await this.$store.dispatch('store/UPDATE_FILTERS', { ...this.filtersData, offset: 0 });
      this.$emit('apply-product-filter');
      this.$BLRemodal.hide('modal-filter-products');
    },
  },
  mounted() {
    this.cloneFiltersData();
  },
};
</script>
<style src="./ModalFilterProduct.style.scss" lang="scss"></style>

import cities from '../data/cities';

const FilterFreeShipping = {
  props: {
    productFreeShipping: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filterCity: '',
    };
  },
  computed: {
    productFreeShippingData: {
      get() {
        return this.productFreeShipping;
      },
      set(val) {
        this.$emit('product-free-shipping-change', val);
      },
    },
    cities() {
      return cities.filter(
        city =>
          city.coverage.filter(cov => cov.name.toUpperCase().indexOf(this.filterCity.toUpperCase()) !== -1).length > 0,
      );
    },
  },
};

export default FilterFreeShipping;

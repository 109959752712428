import { mapState } from 'vuex';
import { removeProperty } from 'shared/javascripts/utils/object-helpers';
import ageRestrictionMixin from './age-restriction';
import MerchantPageAppRouteMixin from './route';

const MerchantPageAppMixin = {
  mixins: [MerchantPageAppRouteMixin, ageRestrictionMixin],
  watch: {
    $route(to, from) {
      if (from.name !== null && global.document) {
        global.document.title = this.metaTitle;
      }
    },
  },
  computed: {
    ...mapState(['updatedPath']),
    ...mapState('store', ['store', 'labels', 'brand']),
    rootComponent() {
      return this.$route.name ? 'router-view' : 'MerchantPage';
    },
    pageInfo() {
      const { page } = this.$route.query;
      return page && page > 1 ? `(Halaman ${page}) ` : '';
    },
    metaTitle() {
      const labelSlug = this.$route.params.label_slug;
      const { store, brand } = this;
      if (brand && brand.id) {
        return `${brand && brand.meta_title} ${this.pageInfo}| Bukalapak`;
      }

      return labelSlug
        ? `${labelSlug} - Lapak ${store.name} di ${store.address.city} ${this.pageInfo}| Bukalapak`
        : `Lapak ${store.name} di ${store.address.city} ${this.pageInfo}| Bukalapak`;
    },
    metaDescription() {
      const { store, brand } = this;
      if (brand && brand.id) {
        return brand && brand.meta_description;
      }
      let cityDescription = store.address.province ? ` daerah ${store.address.province},` : '';
      cityDescription += store.address.city ? ` ${store.address.city}` : '';
      cityDescription += ' - Tempat Belanja Termurah dan Teraman hanya di Bukalapak';

      return `Temukan kebutuhanmu di Lapak ${store.name}${cityDescription}${this.pageInfo}`;
    },
  },
  created() {
    this.getAgeRestrictedStore();
    this.updateRoute();
    this.init();
  },

  methods: {
    updateRoute() {
      const { path: pathname, params, query } = this.$route;
      const { label_slug: routeLabelSlug } = params;
      const { label_slug: labelSlug } = query;
      const currentPathname =
        pathname.length && pathname[pathname.length - 1] === '/'
          ? pathname.substring(0, pathname.length - 1)
          : pathname;

      const path =
        !routeLabelSlug && labelSlug ? `${this.updatedPath}/label/${labelSlug}` : this.updatedPath.replace(/\/$/, '');

      if (currentPathname !== path) {
        const newQuery = removeProperty(this.$route.query, 'label_slug');
        this.$router.replace({ path, query: { ...newQuery } });
      }
    },
    async init() {
      const { username, id, brand: brandId } = this.$route.params;

      let fallbackHappens = false;

      if ((username || id || (this.brand && this.brand.id)) && !(this.store && this.store.id)) {
        await this.fallbackStore({ username, id: id || (this.brand && this.brand.id) });
        if (!(this.brand && this.brand.id) && this.store.brand_seller) {
          await this.fallbackBrand(this.store.id);
        }
        fallbackHappens = true;
      }

      if (!(this.brand && this.brand.id) && (brandId || (this.store && this.store.id && this.store.brand_seller))) {
        await this.fallbackBrand(brandId || (this.store && this.store.id));
        if (!(this.store && this.store.id)) {
          await this.fallbackStore({ id: this.brand.id });
        }
        fallbackHappens = true;
      }

      if (fallbackHappens && this.store.id) {
        this.updateMeta();
      }
    },
    async fallbackStore({ id, username }) {
      try {
        if (id) {
          await this.$store.dispatch('store/GET_STORE', id);
        } else {
          await this.$store.dispatch('store/GET_STORE_PROFILE', username);
        }
      } catch (e) {
        this.fallbackError(e);
      }
    },
    async fallbackBrand(brand) {
      try {
        await this.$store.dispatch('store/GET_BRAND', brand);
      } catch (e) {
        this.fallbackError(e);
      }
    },
    fallbackError(e) {
      if (typeof window !== 'undefined') {
        const errorCode = e.meta && e.meta.http_status === 404 ? 404 : 500;
        window.location.assign(`${process.env.BUKALAPAK_URL}/${errorCode}`);
      }
    },

    updateMeta() {
      if (global.document) {
        global.document.title = this.metaTitle;
        const metaDesc = global.document.querySelector('meta[name="description"]');
        const metaOgDesc = global.document.querySelector('meta[property="og:description"]');
        if (metaDesc) {
          metaDesc.setAttribute('content', this.metaDescription);
        }
        if (metaOgDesc) {
          metaOgDesc.setAttribute('content', this.metaDescription);
        }
      }
    },
  },
};

export default MerchantPageAppMixin;

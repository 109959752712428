<template>
  <div>
    <div class="o-layout u-mrgn-bottom--4" :key="componentKey">
      <div class="o-layout__item u-mrgn-bottom--3">
        <bl-checkbox-av
          label="Semua Rating"
          :checked="productRatingData.length === 0"
          color="red"
          @change="setValue(0)"
        />
      </div>
      <div class="o-layout__item u-mrgn-bottom--3" v-for="rating in ratingKeys" :key="rating">
        <bl-checkbox-av :checked="productRatingData.indexOf(5 - rating) !== -1" color="red" @change="setValue(rating)">
          <bl-rating-mv :value="rating * 20" :max-ratings="5" variant="star" />
        </bl-checkbox-av>
      </div>
    </div>
  </div>
</template>

<script>
import { BlCheckboxAv, BlRatingMv } from '@bukalapak/bazaar-dweb';

export default {
  name: 'FilterProductRating',
  components: {
    BlCheckboxAv,
    BlRatingMv,
  },
  props: {
    productRating: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      componentKey: 0,
      ratingKeys: [5, 4, 3, 2, 1],
    };
  },
  computed: {
    productRatingData() {
      const rating = this.productRating.split(':');
      const valueRating = Number(rating.length > 1 ? rating[0] : '0', 10);
      return valueRating ? Array.from(Array(6 - valueRating).keys()) : [];
    },
  },
  methods: {
    setValue(val) {
      this.componentKey += 1;
      this.$emit('product-rating-change', val ? `${val}:5` : '');
    },
  },
};
</script>

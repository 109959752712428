const removeSpecialCharacters = url => url.toString().replace(/[^\w-]/gi, '');
const sanitizeText = text => {
  let sanitizedText = text;
  [
    [/</g, '&lt;'],
    [/>/g, '&gt;'],
    [/'/g, '&#39;'],
    [/"/g, '&#34;'],
  ].forEach(([regex, replacement]) => {
    sanitizedText = sanitizedText.replace(regex, replacement);
  });

  return sanitizedText;
};
const sanitizeMetaText = text =>
  text
    ? text
        .replace(/<[^>]+>/g, '')
        .replace(/[<>]/g, '')
        .replace(/{{+|}}+/g, '')
        .replace(/"/g, '')
        .replace(/\\/g, '\\\\')
    : '';

const stripHtml = html => html.replace(/(<([^>]+)>)/gi, '');

const highlight = (text, query, className = 'text-highlight') =>
  text
    .toString()
    .replace(
      new RegExp(`(${query.trim().replace(/ +/g, '|')})`, 'ig'),
      match => `<span class="${className}">${match}</span>`,
    );

module.exports = {
  removeSpecialCharacters,
  sanitizeText,
  sanitizeMetaText,
  stripHtml,
  highlight,
};

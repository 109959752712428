<template>
  <div class="u-align-center u-border-dashed--2--ash u-border-radius--4 u-pad-v--4 u-width-1">
    <div class="u-fg--black u-margin-auto u-mrgn-auto u-mrgn-bottom--2 u-txt--fair u-width-230">
      Tampilkan berbagai pilihan barang unggulan kamu di sini
    </div>
    <bl-button-av color="red" :href="sellerCenterPHSettingUrl">Atur Barang Unggulan</bl-button-av>
  </div>
</template>

<script>
import { BlButtonAv } from '@bukalapak/bazaar-dweb';

export default {
  name: 'MerchantPageHighlightsEmpty',
  components: {
    BlButtonAv,
  },
  computed: {
    sellerCenterPHSettingUrl() {
      return `${process.env.SELLER_CENTER_URL}/promotions/product-highlight`;
    },
  },
};
</script>
<style lang="scss" scoped>
.u-width-230 {
  width: 230px;
}
</style>

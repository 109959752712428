<template>
  <BlFlexContainerMv direction="row">
    <BlFlexItemAv
      v-for="(item, index) in banners"
      :key="`banner-${index}`"
      :margin="`0px 0px 0px ${index > 0 ? '12px' : ''}`"
      grow="0"
      shrink="1"
      basis="25%"
      class="ut-banner"
    >
      <div class="c-banner-thumb__item--tall c-banner-thumb__item">
        <BrandPageBannerItem
          class="c-banner-thumb__img"
          :type="item.banner_type"
          :src="item.image_url"
          :url="item.url"
        />
      </div>
    </BlFlexItemAv>
  </BlFlexContainerMv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import BannerMixins from 'shared/javascripts/mixins/merchant-page/banner';
import BrandPageBannerItem from '../../BrandPageBannerItem';

export default {
  name: 'BrandPageBannerType3',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BrandPageBannerItem,
  },
  mixins: [BannerMixins],
};
</script>

<style lang="scss">
.c-banner-thumb__item {
  &--tall {
    display: flex;
    align-items: center;
    background-color: #333;
    overflow: hidden;
    border-radius: 4px;
    height: 376px;

    @media only screen and (min-width: 82.563em) {
      height: 420px;
    }

    .banner-image {
      height: 100%;
    }

    img,
    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>

const BUKAMALL_LOGO_URL = 'https://s0.bukalapak.com/pavilion/1586920121610/original/bukamall-logo.png';
const BUKAMALL_LOGO_WHITE_URL = 'https://s0.bukalapak.com/pavilion/1586920137167/original/bukamall-logo-white.png';
const BUKAMALL_ICON_URL = 'https://s0.bukalapak.com/pavilion/1586932453540/original/bukamall-icon.png';
const BUKAMALL_ICON_WHITE_URL =
  'https://s0.bukalapak.com/zhouman/1602142218509/original/1601996967128-ico-bukamall-minor.jpeg';

export default {
  data() {
    return {
      BUKAMALL_LOGO_URL,
      BUKAMALL_LOGO_WHITE_URL,
      BUKAMALL_ICON_URL,
      BUKAMALL_ICON_WHITE_URL,
    };
  },
};

import ClientOnly from 'vue-client-only';
import VueRouter from 'vue-router';
import HoneyBagderNotify, { errorHandler } from 'plugins/honeybadger';
import { sync } from 'vuex-router-sync';

import BLRemodalPlugin from 'plugins/BLRemodal';
import 'lazysizes';
import { money } from 'shared/javascripts/utils/number-helper';

import createStore from './config/stores';
import createRouter from './config/router';

import MerchantPageApp from './MerchantPageApp.vue';
import '@bukalapak/bazaar-mweb/dist/bazaar.css';

export const createApp = ({ Vue, Vuex, isSSR = false }) => {
  Vue.use(Vuex);
  Vue.use(VueRouter);
  Vue.use(BLRemodalPlugin);

  Vue.use(HoneyBagderNotify);
  Vue.config.errorHandler = (err, vm, info) => {
    errorHandler({
      err,
      component: vm.name,
      action: '',
      fingerprint: 'spica/merchant_page_westeros_dweb',
      context: info,
    });
  };

  Vue.filter('money', value => money(value, 0, 'Rp', '.', ','));

  if (!isSSR) {
    Vue.component(ClientOnly.name, ClientOnly);
  }

  const store = createStore(Vuex);
  const router = createRouter(VueRouter);

  sync(store, router);

  const app = new Vue({
    store,
    router,
    data: () => ({
      isSSR,
    }),
    components: {
      MerchantPageApp,
    },
    template: `<div id="merchant-page"><merchant-page-app  /></div>`,
    methods: {
      notifyError(err, action, payload, component) {
        this.$hbNotify({
          err,
          component,
          action,
          context: { payload },
          fingerprint: 'spica/merchant_page_westeros_dweb',
        });
      },
    },
  });

  return {
    app,
    store,
    router,
  };
};

export default createApp;

<template>
  <div v-if="!loading">
    <BlPanelMv class="u-bg--white" v-if="products.length && !isError">
      <div class="c-samsung-highlight--head">
        <img class="u-mrgn-auto" :alt="name" :src="getAssetUrl(`images/samsung/catalog/${image}.jpg`)" />
      </div>
      <div class="c-samsung-highlight--body">
        <BlFlexContainerMv>
          <BlFlexItemAv padding="8px" basis="20%" v-for="product in products" :key="`product-highlights-${product.id}`">
            <BrandPageBannerType4Card :product="product" />
          </BlFlexItemAv>
        </BlFlexContainerMv>
      </div>
    </BlPanelMv>
  </div>
  <div v-else>
    <BlPanelMv class="u-bg--white ut-highlights-loading--container">
      <div class="c-samsung-highlight--body">
        <div class="c-samsung-highlight--head">
          <BlPreloaderAv loading-class="u-height--274 u-width-1" />
        </div>
        <BlFlexContainerMv>
          <BlFlexItemAv
            class="ut-highlights-loading--item"
            padding="8px"
            basis="20%"
            v-for="index in 5"
            :key="`highlights-loading--${index}`"
          >
            <BlPreloaderAv loading-class="u-height--150 u-width-1" />
          </BlFlexItemAv>
        </BlFlexContainerMv>
      </div>
    </BlPanelMv>
  </div>
</template>

<script>
import { stores } from 'shared/javascripts/api/merchant/api-modules';
import { BlPreloaderAv, BlPanelMv } from '@bukalapak/bazaar-dweb';
import AssetsMixin from 'shared/javascripts/mixins/assets';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import BrandPageBannerType4Card from '../BrandPageBannerType4Card';

export default {
  name: 'BrandPageBannerType4Highlight',
  components: {
    BlPanelMv,
    BlFlexContainerMv,
    BlFlexItemAv,
    BrandPageBannerType4Card,
    BlPreloaderAv,
  },
  mixins: [AssetsMixin, StoreStateMixin],
  props: {
    labelId: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      products: [],
      isError: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const { data } = await stores.$products.retrieveMerchantPageProducts(this.store.id, { queryParams: { label_id: this.labelId, limit: 5 } });
        this.products = data;
        this.loading = false;
      } catch (e) {
        this.isError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-samsung-highlight {
  &--head {
    height: 337px;
    width: 100%;
  }

  &--body {
    background-color: #fafafa;
    padding: 16px;
  }
}
</style>

<template>
  <BlFlexContainerMv direction="row">
    <BlFlexItemAv basis="50%">
      <div class="u-position-relative">
        <BlScrollerbox
          class="c-scrollerbox--arrow-box c-scrollerbox--arrow-below-box c-scrollerbox--arrow-dark"
          :collection="mainBanner"
          :content-window="1"
          :scroll-offset="1"
        >
          <div
            class="ut-main-banner c-scrollerbox__item js-scrollerbox__item u-width-1"
            v-for="(item, index) in mainBanner"
            :key="`main-banner-${index}`"
          >
            <div class="c-banner-thumb__item c-banner-thumb__item--xlarge">
              <BrandPageBannerItem
                class="c-banner-thumb__img"
                :type="item.banner_type"
                :src="item.image_url"
                :url="item.url"
              />
            </div>
          </div>
        </BlScrollerbox>
      </div>
    </BlFlexItemAv>
    <BlFlexItemAv basis="50%">
      <BlFlexContainerMv direction="row" alignItems="center" justifyContent="space-between" wrap="wrap">
        <BlFlexItemAv
          basis="50%"
          v-for="(item, index) in secondaryBanner"
          :key="`secondary-banner-${index}`"
          shrink="1"
          padding="0px 0px 0px 12px"
          :margin="`0px 0px ${index < 2 ? '12px' : '0px'} 0px`"
          class="ut-secondary-banner"
        >
          <div class="c-banner-thumb__item c-banner-thumb__item--small">
            <BrandPageBannerItem
              class="c-banner-thumb__img"
              :type="item.banner_type"
              :src="item.image_url"
              :url="item.url"
            />
          </div>
        </BlFlexItemAv>
      </BlFlexContainerMv>
    </BlFlexItemAv>
  </BlFlexContainerMv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import BlScrollerbox from 'shared/javascripts/components/BlScrollerbox';
import BannerMixins from 'shared/javascripts/mixins/merchant-page/banner';
import BrandPageBannerItem from '../../BrandPageBannerItem';

export default {
  name: 'BrandPageBannerType1',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlScrollerbox,
    BrandPageBannerItem,
  },
  mixins: [BannerMixins],
};
</script>

<style lang="scss">
.c-banner-thumb__item {
  &--xlarge {
    display: flex;
    align-items: center;
    background-color: #333;
    overflow: hidden;
    border-radius: 4px;
    height: 408px;

    @media only screen and (min-width: 82.563em) {
      height: 420px;
    }

    iframe,
    img {
      width: 100%;
    }
  }

  &--small {
    height: 198px;
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    align-items: center;
    background-color: #333;

    iframe,
    img {
      width: 100%;
    }
    @media only screen and (min-width: 82.563em) {
      height: 204px;
    }
  }
}
</style>

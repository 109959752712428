<template>
  <div id="merchant-page-app">
    <client-only>
      <MerchantPageAgeRestrictionModal />
      <flash-message class="flashpool" transition-name="flash" />
    </client-only>
    <component v-if="store.id" v-bind:is="rootComponent"></component>
  </div>
</template>

<script>
import MerchantPageAppMixin from 'shared/javascripts/mixins/merchant-page/app';
import MerchantPageAgeRestrictionModal from './components/MerchantPageAgeRestrictionModal';
import MerchantPage from './pages/MerchantPage.vue';

export default {
  name: 'MerchantPageApp',
  mixins: [MerchantPageAppMixin],
  components: {
    MerchantPage,
    MerchantPageAgeRestrictionModal,
  },
  watch: {
    isAgeRestricted: {
      handler(val) {
        if (val) {
          this.$BLRemodal.show('age-restriction-modal');
        }
      },
      immediate: true,
    },
  },
};
</script>

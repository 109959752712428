<template>
  <div class="c-card-samsung">
    <a :title="product.name" :href="productUrl">
      <div class="c-card-samsung--head">
        <WebP :src="imageProduct" :webp="true">
          <BlImage class="c-card-samsung--head__image" :data-src="imageProduct" />
        </WebP>
      </div>
    </a>
    <div class="c-card-samsung--body">
      <a class="c-card-samsung--body__name" :title="product.name" :href="productUrl">
        {{ product.name }}
      </a>
      <div class="u-mrgn-top--2">
        <template v-if="hasDeal">
          <span class="u-fg--red-brand u-txt--bold ut-deal">
            {{ product.deal.discount_price | money }}
          </span>
          <span class="u-fg--ash-dark u-txt--small u-txt--strikethrough">
            {{ product.deal.original_price | money }}
          </span>
        </template>
        <span v-else class="u-display-block u-fg--red-brand u-txt--bold ut-no-deal">
          {{ product.price | money }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import WebP from 'shared/javascripts/components/WebP';
import BlImage from 'shared/javascripts/components/BlImage';
import { DEFAULT_IMAGE_PRODUCT } from 'shared/javascripts/utils/images';

export default {
  name: 'BrandPageBannerType4Card',
  components: {
    BlImage,
    WebP,
  },
  props: {
    product: {
      required: true,
      type: Object,
    },
    query: {
      type: String,
      default: '',
    },
  },
  computed: {
    imageProduct() {
      const { product } = this;
      return product.images.large_urls && product.images.large_urls.length
        ? product.images.large_urls[0]
        : DEFAULT_IMAGE_PRODUCT;
    },
    productUrl() {
      return this.query ? `${this.product.url}?${this.query}` : this.product.url;
    },
    hasDeal() {
      const { deal } = this.product;
      if (deal && deal.applied_date && deal.expired_date) {
        const validAppliedDate = Date.parse(deal.applied_date) <= Date.now();
        const validExpiredDate = Date.parse(deal.expired_date) > Date.now();
        return validAppliedDate && validExpiredDate;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-card-samsung {
  background-color: #fff;

  &:hover {
    -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  }

  &--head {
    position: relative;
    width: 100%;
    padding-top: 100%;

    &__image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--body {
    padding: 16px;

    &__name {
      color: #333;
      font-size: 14px;
      height: 40px;
      /* stylelint-disable */
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: normal;
      /* stylelint-enable */
      -webkit-line-clamp: 2;

      &:hover {
        color: #333;
      }
    }
  }
}
</style>

<template>
  <BlFlexContainerMv direction="row">
    <BlFlexItemAv basis="50%">
      <div class="c-banner-thumb__item--xlarge">
        <BlPreloaderAv class="u-height-1 u-width-1" loading-class="u-height-1 u-width-1 ut-main-banner-preloader" />
      </div>
    </BlFlexItemAv>
    <BlFlexItemAv basis="50%">
      <BlFlexContainerMv direction="row" alignItems="center" justifyContent="space-between" wrap="wrap">
        <BlFlexItemAv
          basis="50%"
          v-for="index in 4"
          :key="`loading-banner-${index}`"
          shrink="1"
          padding="0px 0px 0px 12px"
          :margin="`0px 0px ${index <= 2 ? '12px' : '0px'} 0px`"
          class="ut-secondary-banner-preloader"
        >
          <div class="c-banner-thumb__item--small">
            <BlPreloaderAv class="u-height-1 u-width-1" loading-class="u-height-1 u-width-1" />
          </div>
        </BlFlexItemAv>
      </BlFlexContainerMv>
    </BlFlexItemAv>
  </BlFlexContainerMv>
</template>

<script>
import { BlPreloaderAv } from '@bukalapak/bazaar-dweb';
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';

export default {
  name: 'BrandPageBannerLoading',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlPreloaderAv,
  },
};
</script>

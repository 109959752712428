<template>
  <div class="brand-banners" v-if="!loading">
    <BrandPageBannerType1 class="ut-template_1" v-if="type === 'template_1'" :banners="banners" />
    <BrandPageBannerType2 class="ut-template_2" v-if="type === 'template_2'" :banners="banners" />
    <BrandPageBannerType3 class="ut-template_3" v-if="type === 'template_3'" :banners="banners" />
    <BrandPageBannerType4 class="ut-template_4" v-if="type === 'template_4'" :banners="banners" />
  </div>
  <BrandPageBannerLoading class="ut-container-loading-banner" v-else />
</template>

<script>
import BrandPageBannerType1 from './BrandPageBannerType/BrandPageBannerType1';
import BrandPageBannerType2 from './BrandPageBannerType/BrandPageBannerType2';
import BrandPageBannerType3 from './BrandPageBannerType/BrandPageBannerType3';
import BrandPageBannerLoading from './BrandPageBannerLoading';
import BrandPageBannerType4 from './BrandPageBannerType/BrandPageBannerType4';

export default {
  name: 'BrandPageBanner',
  components: {
    BrandPageBannerType1,
    BrandPageBannerType2,
    BrandPageBannerType3,
    BrandPageBannerLoading,
    BrandPageBannerType4,
  },
  props: {
    type: {
      type: String,
      default: 'template_1',
      validator(value) {
        return ['template_1', 'template_2', 'template_3', 'template_4'].includes(value);
      },
    },
    banners: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss">
/deep/ .c-scrollerbox__arrow {
  top: 50% !important;
}
/deep/ .c-banner-thumb__item:hover .c-banner-thumb__img {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
  transform: scale(1.02);
}
/deep/ .c-banner-thumb__img {
  display: table-cell;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.brand-banners .section:not(:last-child) {
  margin-bottom: 12px;
}
</style>

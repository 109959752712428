<template>
  <transition name="c-remodal-transition">
    <div v-if="visible" :class="remodalSizingClass()" :style="{ height: window.height + 'px' }" class="c-remodal">
      <div class="c-remodal__wrapper" @mousedown.stop="onBackgroundClick" @touchstart.stop="onBackgroundClick">
        <div ref="modal" class="c-remodal__content">
          <button v-if="showCloseBtn" class="c-remodal__close" type="button" @click="toggle(false)">
            <bl-icon-av variant="close" />
          </button>

          <div class="c-remodal__panel" @mousedown.stop @touchstart.stop>
            <div v-if="title" class="c-remodal__panel__head">
              <div class="c-remodal__panel__title">
                {{ title }}
              </div>
            </div>
            <div class="c-remodal__panel__body">
              <slot />
            </div>
            <div v-if="hasFooter" class="c-remodal__panel__footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { BlIconAv } from '@bukalapak/bazaar-dweb';
import remodalEventBus from './remodalEventBus';

export default {
  name: 'BLCRemodal',
  components: {
    BlIconAv,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
      validator(value) {
        const sizing = ['small', 'default', 'medium', 'large'];
        const found = sizing.indexOf(value);
        return found !== -1;
      },
    },
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    overlayClickToClose: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer;
    },
  },
  watch: {
    /**
     * Sets the visibility of overlay and modal.
     * Events 'opened' and 'closed' is called here
     * inside `setTimeout` and `$nextTick`, after the DOM changes.
     * This fixes `$refs.modal` `undefined` bug
     */
    visible(value) {
      if (value) {
        // this.visibility.overlay = true;

        setTimeout(() => {
          // this.visibility.modal = true;
          this.$nextTick(() => {
            this.callAfterEvent(true);
          });
        }, this.delay);
      } else {
        // this.visibility.modal = false;

        setTimeout(() => {
          // this.visibility.overlay = false;
          this.$nextTick(() => {
            this.callAfterEvent(false);
          });
        }, this.delay);
      }
    },
  },
  beforeMount() {
    remodalEventBus.$on('toggle', (name, state) => {
      if (name === this.name) {
        // if undefine, state toggle the modal
        let toggleState = state;
        if (typeof toggleState === 'undefined') {
          toggleState = !this.visible;
        }
        this.toggle(toggleState);
      }
    });

    window.addEventListener('resize', this.onWindowResize);

    this.onWindowResize();
  },
  methods: {
    toggle(state) {
      const beforeEventName = this.visible ? 'before-close' : 'before-open';

      let stopEventExecution = false;

      const stop = () => {
        stopEventExecution = true;
      };

      const beforeEvent = this.genEventObject({ stop, state });

      this.$emit(beforeEventName, beforeEvent);

      if (!stopEventExecution) {
        if (state) {
          document.documentElement.style.overflowY = 'hidden';
        } else {
          document.documentElement.style.overflowY = '';
        }
        this.visible = state;
        // after events are called in `watch.visible`
      }
    },
    genEventObject(params) {
      const eventData = {
        name: this.name,
        timestamp: Date.now(),
        ref: this.$refs.modal,
      };

      return Object.assign(eventData, params || {});
    },
    callAfterEvent(state) {
      const afterEventName = state ? 'opened' : 'closed';

      const afterEvent = this.genEventObject({ state });

      this.$emit(afterEventName, afterEvent);
    },
    onBackgroundClick() {
      if (this.overlayClickToClose) {
        this.toggle(false);
      }
    },
    onWindowResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    remodalSizingClass() {
      if (this.size !== 'default') {
        return `c-remodal--${this.size}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
// ==========================================================================
// Remodal
// ==========================================================================

$remodal-width--small: 200px;
$remodal-width: 400px;
$remodal-width--medium: 600px;
$remodal-width--large: 800px;
$remodal-index: 1000;
$remodal-spacing: 24px;
$remodal-spacing--small: 20px;
$remodal-close-btn-color: #333;
$remodal-close-btn-font-size: 24px;
$remodal-backdrop-bgcolor: #000;
$remodal-panel-bgcolor: #fff;
$remodal-border-color: #ddd;
$remodal-border-radius: 4px;
$remodal-head-bgcolor: #fff;
$remodal-title-font-size: 18px;
$remodal-title-font-size--small: 16px;

.c-remodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $remodal-index;
  overflow: hidden;
}

.c-remodal__wrapper {
  position: fixed;
  z-index: $remodal-index + 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: block;
  padding: ($remodal-spacing--small / 2) ($remodal-spacing--small / 2) 0;
  background-color: rgba($remodal-backdrop-bgcolor, 0.4);
  text-align: center;
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;

  &:after {
    display: inline-block;
    height: 100%;
    margin-left: -0.05em;
    content: '';
  }

  @media only screen and (min-width: 1024px) {
    padding: $remodal-spacing $remodal-spacing 0;
  }
}

.c-remodal__content {
  position: relative;
  z-index: $remodal-index + 2;
  margin-bottom: $remodal-spacing;
  display: inline-block;
  width: 100%;
  max-width: $remodal-width;
  text-align: left;
}

.c-remodal__content,
.c-remodal__wrapper:after {
  vertical-align: middle;
}

.c-remodal__close {
  position: absolute;
  top: 3px;
  right: 14px;
  z-index: 1;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  color: $remodal-close-btn-color;
  opacity: 0.4;
  font-size: $remodal-close-btn-font-size;

  @media only screen and (min-width: 1024px) {
    top: 6px;
    right: 16px;
  }
}

.c-remodal__panel {
  background-color: $remodal-panel-bgcolor;
  position: relative;
  width: 100%;
  border-radius: $remodal-border-radius;

  &__head {
    position: relative;
    display: block;
    padding: 16px 36px 16px 20px;
    background-color: $remodal-head-bgcolor;
    border-bottom: 1px solid $remodal-border-color;
    border-radius: $remodal-border-radius $remodal-border-radius 0 0;

    @media only screen and (min-width: 1024px) {
      padding: 18px 36px 18px 24px;
    }
  }

  &__title {
    font-size: $remodal-title-font-size--small;
    font-weight: bold;
    line-height: 1;

    @media only screen and (min-width: 1024px) {
      font-size: $remodal-title-font-size;
    }
  }

  &__body {
    display: block;
    padding: $remodal-spacing--small;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    position: relative;

    &:before,
    &:after {
      content: ' ';
      display: table;
    }

    &:after {
      clear: both;
    }

    @media only screen and (min-width: 1024px) {
      padding: $remodal-spacing;
    }
  }

  &__footer {
    position: relative;
    display: block;
    padding: 16px 20px;
    background-color: $remodal-panel-bgcolor;
    border-top: 1px solid $remodal-border-color;
    border-radius: 0 0 $remodal-border-radius $remodal-border-radius;

    @media only screen and (min-width: 1024px) {
      padding: 18px 24px;
    }
  }
}

//
// Remodal Size Variant (Desktop only)
// ==========================================================================

@media only screen and (min-width: 1024px) {
  .c-remodal--small .c-remodal__content {
    max-width: $remodal-width--small;
  }

  .c-remodal--medium .c-remodal__content {
    max-width: $remodal-width--medium;
  }

  .c-remodal--large .c-remodal__content {
    max-width: $remodal-width--large;
  }
}

//
// Remodal transition
// ==========================================================================

.c-remodal-transition-enter,
.c-remodal-transition-leave-to {
  opacity: 0;

  .c-remodal__content {
    transform: scale(0.8);
  }
}

.c-remodal-transition-leave,
.c-remodal-transition-enter-to {
  opacity: 1;

  .c-remodal__content {
    transform: scale(1);
  }
}

.c-remodal-transition-enter-active,
.c-remodal-transition-leave-active {
  transition: opacity 0.3s ease-out;

  .c-remodal__content {
    transition: all 0.3s ease-out;
  }
}
</style>

export default () => ({
  label_id: '',
  keywords: '',
  offset: 0,
  limit: 16,
  price_range: '',
  sort: 'bestselling',
  deal: '',
  condition: '',
  rating: '',
  installment: '',
  couriers: [],
  free_shipping_provinces: '',
  wholesale: '',
});

<template>
  <img
    class="lazyload"
    loading="lazy"
    v-bind="$attrs"
    :src="getPlaceholder"
    :onError="onError"
    @error="onErrorFallback"
  />
</template>

<script>
const placeholderImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';

export default {
  name: 'BlImage',
  props: {
    placeholder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getPlaceholder() {
      if (this.placeholder) {
        return placeholderImage;
      }
      return '';
    },
    onError() {
      if (this.placeholder) {
        return `this.onerror=null;this.src='${placeholderImage}';`;
      }
      return '';
    },
  },
  methods: {
    onErrorFallback(event) {
      const { parentElement } = event.target;
      const otherSource = (parentElement && parentElement.querySelectorAll('source')) || [];
      otherSource.forEach(el => {
        el.srcset = placeholderImage;
      });
      event.target.src = placeholderImage;
    },
  },
};
</script>

<style></style>
